/* global vvip */

import { TextToSpeech } from '@ionic-native/text-to-speech'

// 文字转语音
export const tts = {
    _config: {
        rate: 0.8, // 默认朗读速度
    },

    // 朗读文本
    async speak(options) {
        Object.assign(vvip.tts._config, typeof options === 'string' ? { text: options } : options)
        await TextToSpeech.speak(options)
    },

}

