export default {
    app: {
        name: 'Passwords',
        copyright: '© 2022 The VVIP Team 版权所有. 所有权利保留. ',
    },

    data: {
        generator: {
            label: '生成器',
            auto: {
                label: '自动生成',
                on: '启动和设置更新时自动生成新密码',
                off: '仅通过手动按钮生成密码',
            },
            quantity: {
                label: '密码数量',
                placeholder: '每次生成的密码数量',
                hint: '任何1到10之间的整数',
            },
        },
        presets: {
            name: {
                label: '预设名称',
                placeholder: '任何你喜欢的名称',
            },
            easy: '易于阅读的强密码', 
            lucky7: '包含幸运7的强密码', 
            fortun8: '包含吉利8的强密码', 
            symbols: '包含符号的加强密码',
        },
        options: {
            upper: '大写字母',
            lower: '小写字母',
            numbers: '数字',
            symbols: '符号',
            similar: '相似字符 (0OIl)',
            ambiguous: '歧义字符 (如\\`\'")',
            length: {
                label: '密码长度',
                placeholder: '生成的密码字符数量',
                hint: '任何不超过1024的正整数',
            },
        },
        runtime: {
            name: {
                label: '@:data.presets.name.label',
                placeholder: '@:data.presets.name.placeholder',
            },
        },
    },

    passwords: {
        generate: '生成密码',
        clear: '清空',
        unsaved: '(未保存的设置)',
        presets: '预设',
        current: '当前预设',
        available: '可用预设',
        rename: '重命名',
        delete: '删除',
        reset: '还原默认预设',
    },

    messages: {
        delete: '这项预设将被删除. 该操作无法取消. 你确定要继续吗? ',
        reset: '对预设进行的所有自定义改动将被还原. 该操作无法取消. 你确定要继续吗? ',
        empty: '未选择任何用于生成密码的字符',
        included: '已包含{group}',
        excluded: '已排除{group}',
        copied: '密码已复制到剪贴板',
    },
}