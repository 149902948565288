/* global vvip */

import vvip_welcome from '../views/welcome.vue'

// 欢迎界面
export const welcome = {
    _config: {
        css: 'vvip-eula', // 用户协议 CSS 样式
    },

    // 检查同意协议状态
    async check() {
        // 如果已同意的协议版本与当前版本不同则弹窗
        if (vvip.data.values.app.eula !== vvip.config.welcome.eula.version) {
            vvip.ui.modal({ component: vvip_welcome, backdropDismiss: false })
        }
    },

    // 用户许可协议
    async eula() {
        const $t = vvip.global.$t
        vvip.ui.modal({
            componentProps: {
                content: await (await fetch(vvip.config.welcome.eula.resource)).text(),
                html: true,
                ok: {
                    label: $t('vvip.common.consent'),
                    action: vvip.welcome.consent,
                },
                cancel: {
                    label: $t('vvip.common.exit'),
                    action: vvip.welcome.decline,
                },
            },
            backdropDismiss: false,
            cssClass: vvip.welcome._config.css,
        })
    },

    // 同意协议
    consent() {
        // 保存同意的协议版本
        vvip.data.set('app', 'eula', vvip.config.welcome.eula.version)
        // 关闭所有弹窗
        vvip.ui.modal.clear()
    },

    // 拒绝协议
    decline() {
        // 删除同意协议记录
        vvip.data.set('app', 'eula', '')
        // 退出App
        vvip.terminate()
    },

}