// 初始数据

export const data = $t => {
    return {
        app: {
            eula: '',
            locale: 'auto',
            dark: 'auto',
        },
        generator: {
            auto: 1,
            quantity: 1,
            customized: 0,
        },
        presets: [
            {
                name: 'PIN (4)',
                options: {
                    length: 4,
                    upper: 0, lower: 0, numbers: 1, symbols: 0, similar: 1, ambiguous: 0,
                    excluded: '', compulsory: '',
                },
            },
            {
                name: 'PIN (6)',
                options: {
                    length: 6,
                    upper: 0, lower: 0, numbers: 1, symbols: 0, similar: 1, ambiguous: 0,
                    excluded: '', compulsory: '',
                },
            },
            {
                name: $t('data.presets.easy'),
                options: {
                    length: 16,
                    upper: 1, lower: 1, numbers: 1, symbols: 0, similar: 0, ambiguous: 0,
                    excluded: '', compulsory: '',
                },
            },
            {
                name: $t('data.presets.lucky7'),
                options: {
                    length: 16,
                    upper: 1, lower: 1, numbers: 1, symbols: 0, similar: 0, ambiguous: 0,
                    excluded: '', compulsory: '7',
                },
            },
            {
                name: $t('data.presets.fortun8'),
                options: {
                    length: 16,
                    upper: 1, lower: 1, numbers: 1, symbols: 0, similar: 0, ambiguous: 0,
                    excluded: '4', compulsory: '8',
                },
            },
            {
                name: $t('data.presets.symbols'),
                options: {
                    length: 16,
                    upper: 1, lower: 1, numbers: 1, symbols: 1, similar: 0, ambiguous: 0,
                    excluded: '', compulsory: '',
                },
            },
            {
                name: 'Token (32)',
                options: {
                    length: 32,
                    upper: 1, lower: 1, numbers: 1, symbols: 0, similar: 0, ambiguous: 0,
                    excluded: '', compulsory: '',
                },
            },
            {
                name: 'Token (64)',
                options: {
                    length: 64,
                    upper: 1, lower: 1, numbers: 1, symbols: 0, similar: 0, ambiguous: 0,
                    excluded: '', compulsory: '',
                },
            },
            {
                name: 'Token (128)',
                options: {
                    length: 128,
                    upper: 1, lower: 1, numbers: 1, symbols: 0, similar: 0, ambiguous: 0,
                    excluded: '', compulsory: '',
                },
            },
        ],
        options:
        {
            length: 16,
            upper: 1, // 数字 (0: 不可用, 1: 全部可用, 2: 部分可用)
            lower: 1, // 数字 (0: 不可用, 1: 全部可用, 2: 部分可用)
            numbers: 1, // 数字 (0: 不可用, 1: 全部可用, 2: 部分可用)
            symbols: 0, // 符号 (0: 不可用, 1: 全部可用, 2: 部分可用)
            similar: 0, // 相似 (-1: 不适用, 0: 不可用, 1: 可用)
            ambiguous: 0, // 歧义 (-1: 不适用, 0: 不可用, 1: 可用)
            excluded: '', // 排除字符
            compulsory: '', // 必备字符
        },
        runtime: {
            name: '', // 当前预设名称
            options: { length: 0, excluded: '', compulsory: '', pool: '' }, // 解析后的选项
            preset: -1, // 当前选项对应的预设ID
            manage: 0, // 预设: 管理模式
            result: [], // 生成的密码列表    
            busy: false, // 是否正在生成
        },
    }
}