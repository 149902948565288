<template>
  <ion-list id="inbox-list" @click="vvip.call(data.click)">
    <ion-list-header>{{ data.structure.label }}</ion-list-header>
    <ion-item
      lines="none"
      v-for="(item, key) in data.structure.select"
      :key="key"
      @click="data.select(key, $event)"
    >
      <ion-note slot="start" :color="data.value === key ? 'primary' : ''">
        <i
          :class="['fa-fw', 'fa-duotone', 'fa-' + item.icon]"
          :color="data.value === key ? 'primary' : ''"
        ></i>
      </ion-note>
      <ion-label :color="data.value === key ? 'primary' : ''">{{
        item.label
      }}</ion-label>
      <ion-note slot="end" :color="data.value === key ? 'primary' : ''">
        <i
          :class="[
            'fa-fw',
            'fa-duotone',
            data.value === key ? 'fa-circle-check' : 'fa-circle',
          ]"
        ></i>
      </ion-note>
    </ion-item>
  </ion-list>
</template>

<script>
import { IonList, IonListHeader, IonItem, IonLabel, IonNote } from "@ionic/vue";

export default {
  name: "vvip-choice",
  components: {
    IonList,
    IonListHeader,
    IonItem,
    IonLabel,
    IonNote,
  },
  props: {
    data: Object,
  },
  computed: {
    // 只支持原生App但当前不是原生模式
    incompatible() {
      return this.data.native && !this.vvip.app.native;
    },

    // 指定禁用或者只支持原生App但当前不是原生模式
    disabled() {
      return this.data.disabled || this.incompatible;
    },
  },
  setup() {
    return {};
  },
};
</script>

<style scoped>
ion-note {
  font-size: unset;
}
</style>