/* global vvip */

import { nextTick } from 'vue'

// 界面外观
const ui = {
    // 预定义颜色
    colors: ['primary', 'secondary', 'tertiary', 'success', 'warning', 'danger', 'light', 'medium', 'dark'],

    // 等待DOM完成渲染
    async render() { await nextTick() },

    // 聚焦输入框
    focus(selector) {
        const input = document.querySelector(selector)
        // 根据是原生输入框还是ionic输入框调用不同方法
        if (input) { input.nodeName === 'INPUT' ? input.focus() : input.setFocus() }
    },

    // 设置语言
    async locale(locale) {
        // 如果未指定或者自动则使用平台语言
        vvip.app.locale = typeof locale === 'undefined' || locale === 'auto' ? vvip.platform.locale : locale

        // 日期格式地区
        vvip.app.region = ['yue-Hans', 'zh-Hans'].includes(vvip.app.locale) ? 'zh-CN' :
            (['yue-Hant', 'zh-Hant'].includes(vvip.app.locale) ? 'zh-TW' : vvip.app.locale)

        // 多语言组件
        vvip.global.$i18n.locale = vvip.app.locale

        // 载入相应语言包的配置文件及数据结构
        Object.assign(vvip.config, vvip.app.config(vvip.global.$t, vvip.app.region))
        Object.assign(vvip.data.structure, vvip.app.structure(vvip.global.$t))
    },

    // 测量屏幕参数
    measure() {
        vvip.screen.density = window.devicePixelRatio // 像素密度
        vvip.screen.width = window.innerWidth // 屏幕宽度
        vvip.screen.height = window.innerHeight // 屏幕高度
        vvip.screen.aspect = vvip.screen.width / vvip.screen.height // 屏幕宽高比
        vvip.screen.ultrawide = vvip.screen.aspect >= 2 // 是否超宽屏
        vvip.screen.landscape = vvip.screen.aspect > 1 // 是否横屏
        vvip.screen.portrait = vvip.screen.aspect <= 1 // 是否竖屏
        vvip.screen.large = vvip.platform.tablet || vvip.platform.desktop || vvip.screen.width >= 992 || vvip.screen.height >= 768 // 是否大屏
        vvip.screen.size = vvip.screen.width < 576 ? 'xs' : (vvip.screen.width < 768 ? 'sm' : (vvip.screen.width < 992 ?
            'md' : (vvip.screen.width < 1200 ? 'lg' : 'xl'))) // 屏幕尺寸
        vvip.screen.ranking = vvip.screen.size === 'xs' ? 1 : (vvip.screen.size === 'sm' ? 2 : (vvip.screen.size === 'md' ?
            3 : (vvip.screen.size === 'lg' ? 4 : 5))) // 屏幕尺寸

    },

    // 占位元素 - { target, reference, style, criteria }
    async placeholder(options) {
        // 等待CSS渲染完成
        await vvip.ui.render()

        let target = document.querySelector(options.target)
        let reference = document.querySelector(options.reference)
        let style = options.style ? options.style : 'height'
        let criteria = options.criteria === undefined ? true :
            (typeof options.criteria === 'function' ? options.criteria() : options.criteria)

        if (target && reference) {
            target.style = style + ': ' + (criteria ? reference.offsetHeight + 'px' : 0)
        }
    },
}

// 监听窗口大小改变及屏幕方向改变事件
ui.measure.monitor = () => {
    window.addEventListener('resize', vvip.ui.measure)
    window.addEventListener('orientationchange', vvip.ui.measure)
    vvip.ui.measure()
}

// 监听窗口大小改变事件
ui.placeholder.set = options => {
    window.addEventListener('resize', () => vvip.ui.placeholder(options))
    vvip.ui.placeholder(options)
}

export { ui }