import { loadingController } from '@ionic/vue'

// 加载进度
const loading = async (options, fixed) => {
    const css = 'vvip-loading-fixed' // 固定宽度 CSS 样式

    // 如果是字符串类型则合并参数
    if (typeof options === 'string') {
        options = { message: options }
        if (fixed) { options.cssClass = css }
    }

    const loading = await loadingController.create(options)
    await loading.present()
    return loading
}

// 更新加载进度
loading.progress = (loading, message) => { loading.querySelector('.loading-content').innerHTML = message }

export { loading }


// // 加载进度
// const loading = async function (options) {
//     const css = 'vvip-loading-fixed' // 固定宽度 CSS 样式

//     // 如果是字符串类型则合并参数
//     if (typeof options === 'string') {
//         options = { message: options }
//         if (options.fixed) { options.cssClass = css }
//     }

//     this.loading = await loadingController.create(options)
//     await this.loading.present()
// }

// // 创建加载进度
// loading.create = async options => await new loading(options)

// // 更新加载进度
// loading.prototype.progress = function (message) { this.querySelector('.loading-content').innerHTML = message }

// export { loading }
