/* global vvip */

// 相关应用
export const related = {
    // 配置
    _config: {
        never: 'vvip-related-never-ask', // 不再提醒相关应用设置
        play: 'https://play.google.com/store/apps/details?id=', // Android 应用详情及下载链接
        android: 'intent:#Intent;action={ID};category=android.intent.category.DEFAULT;category=android.intent.category.BROWSABLE;S.msg_from_browser=Launched%20from%20Browser;end', // Android 应用开启链接
    },

    // 获取已安装的相关应用
    async installed() {
        vvip.app.installed.apps = await navigator.getInstalledRelatedApps()
        vvip.app.installed.pwa = vvip.app.installed.apps.some(app => app.platform === 'webapp')
        vvip.app.installed.android = vvip.app.installed.apps.some(app => app.platform === 'play')
    },

    // 打开 google play
    googleplay() {
        window.open(vvip.related._config.play + vvip.config.about.id)
    },

    // 打开相关应用
    async launch(app) {
        // 取消不再提醒设置
        vvip.related._never = await vvip.storage.get(vvip.related._config.never)
        if (vvip.related._never === null) { vvip.related._never = {} }
        vvip.related._never[app] = false
        vvip.storage.set(vvip.related._config.never, vvip.related._never)

        // 打开指定类型的应用
        if (app === 'android') { window.open(vvip.related._config.android.replace('{ID}', vvip.config.about.id)) }
        else if (app === 'pwa') { window.open(vvip.config.about.url) }

    },

    // 检查已安装的相关应用
    async check() {
        // 获取已安装的相关应用
        await vvip.related.installed()

        // 获取不再提醒设置
        vvip.related._never = await vvip.storage.get(vvip.related._config.never)
        if (vvip.related._never === null) { vvip.related._never = {} }

        // 如果已经安装 Android App 且非原生模式运行则提示启动 Android App
        if (vvip.app.installed.android && !vvip.app.native && !vvip.related._never.android) { vvip.related.prompt('android') }
        // 如果已经安装 Web App 且以网页模式运行则提示启动 Web App
        else if (vvip.app.installed.pwa && vvip.app.web && !vvip.related._never.pwa) { vvip.related.prompt('pwa') }
    },

    // 已安装相关应用提示开启 (app: 相关应用类型)
    async prompt(app) {
        const $t = vvip.global.$t

        const launch = await vvip.ui.alert({
            header: $t('vvip.' + app + '.label'),
            message: $t('vvip.' + app + '.installed'),
            inputs: [
                {
                    type: 'checkbox',
                    label: $t('vvip.common.never'),
                    value: '1',
                    handler: e => vvip.related._never[app] = e.checked,
                },
            ],
            buttons: [
                {
                    text: $t('vvip.common.cancel'),
                    role: 'cancel',
                },
                {
                    text: $t('vvip.common.ok'),
                    role: 'ok',
                }
            ]
        })

        const { role } = await launch.onDidDismiss()
        if (role === 'ok') { vvip.related.launch(app) }
        else if (role === 'cancel' && vvip.related._never[app]) {
            vvip.storage.set(vvip.related._config.never, vvip.related._never)
        }
    },

    // 拦截网页应用系统安装提示
    intercept() {
        window.addEventListener('beforeinstallprompt', async e => {
            e.preventDefault()
            vvip.app.installer = e
        })
    },

    // 安装网页应用 (PWA)
    async install(timeout) {
        const $t = vvip.global.$t

        // 弹出系统安装提示
        vvip.app.installer.prompt()
        // 记录系统安装提示中的用户选择
        const installation = await vvip.app.installer.userChoice
        // 回收安装对象
        vvip.app.installer = null

        // 用户继续安装
        if (installation.outcome === 'accepted') {
            // 正在安装多语言提示消息
            const installing = [...Array(6).keys()].reduce((t, i) => {
                t.push($t(`vvip.pwa.installing[${i}]`))
                return t
            }, [])

            // 显示正在安装界面的第一条消息
            const loading = await vvip.ui.loading(installing[0], true)
            // 正在安装消息步长: 安装超时时限 / 安装消息条数(步长)
            const footlong = timeout / installing.length
            // 在超时时限内每隔1秒检查是否完成安装
            for (let timer = 0, step = 1; timer < timeout; timer++) {
                // 如果计时达到了当前步数则移动到下一步消息
                if (timer >= step * footlong) {
                    vvip.ui.loading.progress(loading, installing[step])
                    step++
                }
                // 获取已安装的相关App
                await vvip.related.installed()
                // 如果完成安装则跳出循环
                if (vvip.app.installed.pwa) { break }
                // 否则一秒钟后再次检查
                else { await vvip.delay(1000) }
            }

            // 关闭正在安装界面
            await loading.dismiss()
            // 如果完成安装则提示启动 Web App
            if (vvip.app.installed.pwa) { vvip.related.prompt('pwa') }
            // 否则给出超时提示
            else {
                vvip.ui.toast({
                    message: $t('vvip.pwa.timeout'),
                    color: 'warning',
                    buttons: [$t('vvip.common.ok')],
                })
            }

        }
    },

    // 原生应用独占特性提示
    native(route) {
        const $t = vvip.global.$t
        vvip.ui.toast({
            duration: 'long',
            message: $t('vvip.native.promotion'),
            color: 'warning',
            buttons: [
                {
                    text: $t('vvip.common.learnmore'),
                    handler: () => { vvip.router.push(route) },
                }
            ],
        })
    },

}