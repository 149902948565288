export default {
    vvip: {
        data: {
            app: {
                label: 'App 設定',
                locale: {
                    label: 'Language',
                    'auto': '自動',
                    'en-AU': 'English',
                    'zh-Hans': '简体中文',
                    'zh-Hant': '繁體中文',
                },
                dark: {
                    label: '暗色主題',
                    off: '停用',
                    auto: '自動',
                    on: '啟用',
                },
            },
        },

         features: {
            header: '版本比較',
            label: '功能特性',
            platform: '支援平台',
            launch: {
                label: '啟動方式',
                browser: '瀏覽器',
                home: '主熒幕',
            },
            offline: '無需網際網路離線作業',
        },

        web: {
            label: '網頁',
        },

        pwa: {
            label: 'Web App',
            promotion: '將本應用添加到主熒幕',
            installed: '已經在此設備上安裝了沉浸式的 Web App. \n\n你想要現在啟動它嗎? ',
            installing: [
                '正在安裝 Web App...',
                '不會太久喔...',
                '請稍候片刻...',
                '只需再多幾秒鐘...',
                '就快好了...',
                '最後一步...',
            ],
            timeout: '有點不對勁. 安裝網頁應用花了比平常更久的時間. 你可能需要重新載入頁面再試一次. ',
        },

        android: {
            label: 'Android App',
            installed: '已經在此設備上安裝了全功能的 Android App. \n\n你想要現在啟動它嗎? ',
        },

        native: {
            promotion: '僅適用於原生 App',
        },

        eula: {
            title: '終端用戶許可證合約',
            first: {
                a: '繼續使用本應用程式即表示你同意',
                b: '. ',
            },
            updated: {
                a: '最近我們對',
                b: '進行了一些變更. 繼續使用本應用程式即表示你同意更新後的合約. ',
            },
            declined: '你已選擇不同意用戶合約. \n請關閉此窗口以終止. ',
        },

        exit: {
            will: '再按一次終止',
            did: '如未能自動終止, 請自行關閉窗口. ',
        },

        common: {
            about: '關於',
            about_app: '@:vvip.common.about{app}',
            released: '{date}發佈',
            ok: '確認',
            cancel: '取消',
            clear: '清除',
            open: '啟動',
            add: '添加',
            get: '下載',
            view: '檢視',
            continue: '繼續',
            consent: '同意並繼續',
            never: '不再詢問',
            learnmore: '瞭解更多',
            preferences: '設定',
            exit: '終止 App',
            debug: '調試',
        },

        messages: {
            select_or_input: '從通用預設中選取, 或者在這裡手動輸入',
            enter_valid_value: '請輸入符合條件的值',
        },
    },
}