import {
    vvip, // 核心组件
    alert, dark, loading, menu, modal, splash, statusbar, toast, // UI 组件
    shuffle, random, // 扩展组件
    vvip_choice, vvip_item, vvip_switch, // 模板
} from '@/vvip-ionic'

vvip.modules({ shuffle, random })
vvip.modules({ alert, dark, loading, menu, modal, splash, statusbar, toast }, 'ui')

export { vvip, vvip_choice, vvip_item, vvip_switch }
