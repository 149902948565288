/* global vvip */

import { Clipboard } from '@awesome-cordova-plugins/clipboard'
import { reactive, computed, watch } from 'vue'

import vvip_editor from '../views/editor.vue'


// 数据
const data = {
    structure: reactive({}), // 数据结构配置
    values: reactive({}), // 数值记录
    computed: {}, // 自动计算的数值

    // 配置
    _config: {
        manual: '#vvip-editor-manual', // 编辑器手动输入文本框
    },

    _editor: reactive({}), // 编辑器内部临时数据

    // 初始数据
    init(options) {
        // 赋予配置中的初始值 (利用JSON创建副本)
        Object.assign(vvip.data.values, vvip.app.data(vvip.global.$t))

        // 自动计算的数据
        if (options.computed) {
            for (const [key, fn] of Object.entries(options.computed)) { vvip.data.computed[key] = computed(fn) }
        }
    },

    // 监控数据
    watch(options) { if (options.watch) { for (const [item, fn] of Object.entries(options.watch)) { watch(item, fn) } } },

    // 加载数据
    async load() {
        for (const cat of Object.keys(vvip.data.values)) {
            if (!vvip.data.structure[cat].instant) {
                let saved = await vvip.storage.get(cat)
                if (saved) { vvip.data.values[cat] = saved }
            }
        }
    },

    // 切换开关
    toggle(cat, key, options) { vvip.data.set(cat, key, vvip.data.values[cat][key] ? 0 : 1, options) },

    // 下一选项
    shift(cat, key, options) {
        vvip.data.set(cat, key, vvip.data.next(vvip.data.values[cat][key], vvip.data.structure[cat].fields[key].select), options)
    },

    // 选择选项
    select(cat, key, value, options) {
        // 阻止冒泡事件
        if (options.event) { options.event.stopPropagation() }
        // 如果值有改变则更新
        if (value !== vvip.data.values[cat][key]) { vvip.data.set(cat, key, value, options) }
    },

    // 设定数值
    set(cat, key, value, options = {}) {
        // 如果指定了key 则根据数据类型保存为字符串或数字
        if (key) {
            value = typeof value !== 'number' && vvip.data.structure[cat].fields[key].number ? Number(value) : value
            // 非数组项目
            if (options.index === undefined) { vvip.data.values[cat][key] = value }
            // 数组项目
            else { vvip.data.values[cat][options.index][key] = value }
        }
        // 否则深层复制整个类别的数据
        else {
            // 非数组项目
            if (options.index === undefined) { vvip.data.values[cat] = vvip.data.clone(value) }
            // 数组项目
            else { vvip.data.values[cat][options.index] = vvip.data.clone(value) }
        }
        // 保存数据
        vvip.data.save(cat, options)
    },

    // 数组插入项目
    insert(cat, index, item, options) {
        // 插入位置
        index = index < 0 ? vvip.data.values[cat].length : index
        // 插入项目
        vvip.data.values[cat].splice(index, 0, vvip.data.clone(item))
        // 保存数据
        vvip.data.save(cat, options)
    },

    // 数组删除项目
    delete(cat, index, options) {
        // 删除项目
        vvip.data.values[cat].splice(index, 1)
        // 保存数据
        vvip.data.save(cat, options)
    },

    // 保存到数据库
    save(cat, options = {}) {
        if (!vvip.data.structure[cat].instant) { vvip.storage.set(cat, vvip.data.values[cat]) }
        // 执行回调方法
        vvip.call(options.callback)
    },

    // 编辑数据
    async edit(cat, key, options) {
        const structure = vvip.data.structure[cat].fields[key]

        // 如果是单一类型的手动输入或者选择输入 则使用alert组件
        if ((structure.manual && !structure.select && !structure.range) ||
            (!structure.manual && structure.select && !structure.range)) {
            vvip.data.popup(cat, key, options)
        }
        // 否则使用专用编辑界面
        else { vvip.data.editor(cat, key, options) }

    },

    // 编辑: 使用alert组件
    async popup(cat, key, options) {
        const $t = vvip.global.$t
        const structure = vvip.data.structure[cat].fields[key]
        const value = options.index === undefined ? vvip.data.values[cat][key] : vvip.data.values[cat][options.index][key]
        const inputs = []
        const buttons = [
            {
                text: $t('vvip.common.cancel'),
                role: 'cancel',
            }
        ]

        // 手动输入
        if (structure.manual) {
            // 加入当前值及数据结构
            inputs.push({
                value,
                ...vvip.data.structure[cat].fields[key].manual
            })
            // 加入确定按钮
            buttons.push({
                text: $t('vvip.common.ok'),
                role: 'ok',
                handler: values => {
                    // 如果通过验证 则将新值应用到正式数据
                    if (vvip.data.validate(values[key], structure.manual).valid) {
                        vvip.data.set(cat, key, values[key], options)
                    }
                    // 否则提示错误并取消更新
                    else {
                        vvip.ui.toast('Please enter a valid value', 'warning')
                        vvip.ui.focus('#' + structure.manual.id)
                        return false
                    }
                },
            })
        }
        // 选择输入
        else {
            // 加入各个选项
            for (const [option, label] of Object.entries(structure.select)) {
                inputs.push({
                    type: 'radio',
                    label,
                    value: option,
                    checked: option === value,
                    handler: () => {
                        if (option !== value) { vvip.data.set(cat, key, option, options) }
                        alert.dismiss()
                    },
                })
            }
        }

        const alert = await vvip.ui.alert({ header: structure.label, inputs, buttons })

        // 如果是手动输入框则赋予焦点
        if (structure.manual) { vvip.ui.focus('#' + structure.manual.id) }
    },

    // 编辑: 使用专用编辑界面
    async editor(cat, key, options) {
        // 将当前编辑项目及回调方法记录到编辑器内部对象
        vvip.data._editor.cat = cat
        vvip.data._editor.key = key
        vvip.data._editor.options = options
        vvip.data._editor.structure = vvip.data.structure[cat].fields[key]

        // 将当前数值更新到待提交数值
        vvip.data._editor.pending = vvip.data.values[cat][key]
        if (vvip.data._editor.structure.manual) { vvip.data._editor.manual = vvip.data._editor.pending }
        if (vvip.data._editor.structure.range) { vvip.data._editor.range = vvip.data._editor.pending }
        vvip.data._editor.invalid = false

        // 弹出编辑界面
        await vvip.ui.modal({
            component: vvip_editor,
            componentProps: { data: vvip.data._editor },
        })
    },

    // 手动输入
    manual(value) {
        // 校验数据        
        const validation = vvip.data.validate(value, vvip.data._editor.structure.manual)

        // 标记数据合法性
        vvip.data._editor.invalid = !validation.valid

        // 更新到已输入数据
        vvip.data._editor.manual = validation.value

        // 如果数据合法则更新到待提交数据
        if (validation.valid) {
            vvip.data._editor.pending = validation.value
            if (vvip.data._editor.structure.range && value.length) { vvip.data._editor.range = value }
        }
    },

    // 校验数据
    validate(value, criteria) {
        // 如果指定了自动大写则转换为大写
        if (criteria.autocapitalize === 'characters') { value = value.toUpperCase() }

        const testee = typeof value === 'number' ? value.toString() : value

        // 返回校验结果 - value: 处理后的数据, valid: 是否合法
        return {
            value,
            valid: (criteria.empty && !testee.length) || (
                (typeof criteria.pattern === 'undefined' ? true : criteria.pattern.test(testee)) &&
                (typeof criteria.maxlength === 'undefined' ? true : criteria.maxlength >= testee.length) &&
                (typeof criteria.minlength === 'undefined' ? true : criteria.minlength <= testee.length) &&
                (typeof criteria.max === 'undefined' ? true : criteria.max >= testee) &&
                (typeof criteria.min === 'undefined' ? true : criteria.min <= testee))
        }
    },

    // 提交数据 - value: 数值, origin: 输入来源 (manual, range, select)
    commit(value, origin) {
        const $t = vvip.global.$t

        // 如果未传入值 (敲回车事件) 则使用文本框中的值
        const enter = value === undefined
        if (enter) {
            value = vvip.data._editor.manual
            origin = 'manual'
        }

        // 如果是文本框手动输入 则校验数据合法性
        if (origin === 'manual') {
            vvip.data.manual(value)

            // 如果不合法
            if (vvip.data._editor.invalid) {
                // 如果设置了警告提示 则弹出提示
                vvip.ui.toast($t('vvip.messages.enter_valid_value'), 'danger', 'top')
                // 聚焦输入框
                vvip.ui.focus(vvip.data._config.manual)
            }
        }
        // 否则标记为合法输入并更新到待提交数据
        else {
            // 如果是来源是范围则转换为字符串
            if (origin === 'range') { value = value.toString() }

            vvip.data._editor.invalid = false
            vvip.data._editor.pending = value
            if (vvip.data._editor.structure.manual) { vvip.data._editor.manual = value }
            if (vvip.data._editor.structure.range && value.length) { vvip.data._editor.range = value }
        }

        // 如果输入合法
        if (!vvip.data._editor.invalid) {
            // 如果有更新 则执行更新
            if (vvip.data._editor.pending !== vvip.data.values[vvip.data._editor.cat][vvip.data._editor.key]) {
                // 将新值应用到正式数据
                vvip.data.set(vvip.data._editor.cat, vvip.data._editor.key, vvip.data._editor.pending, vvip.data._editor.options)
            }
            // 如果是敲回车事件 或者输入来源为选择框触发且设置了自动关闭 或者范围选择触发且指定了自动关闭 则关闭输入界面
            if (enter || (origin == 'select' && vvip.data._editor.structure.close)
                || (origin == 'range' && vvip.data._editor.structure.range.close)) { vvip.ui.modal.dismiss() }
        }
    },

    // 深度合并对象
    merge(...objects) {
        const isObject = obj => obj && typeof obj === 'object'

        return objects.reduce((prev, obj = {}) => {
            for (const key of Object.keys(obj)) {
                const pVal = prev[key]
                const oVal = obj[key]
                if (Array.isArray(pVal) && Array.isArray(oVal)) { prev[key] = pVal.concat(...oVal) }
                else if (isObject(pVal) && isObject(oVal)) { prev[key] = vvip.data.merge(pVal, oVal) }
                else { prev[key] = oVal }
            }
            return prev
        }, {})
    },

    // 克隆: 深度复制数据 (数组或者对象)
    clone(item) { return JSON.parse(JSON.stringify(item)) },

    // 复制到剪贴板 (item: 内容或者DOM元素, dom: 是否DOM元素)
    copy(item, dom) {
        const text = dom ? document.querySelector(item).textContent : item
        if (vvip.app.native) { Clipboard.copy(text) }
        else { navigator.clipboard.writeText(text) }
    },

    // 获取集合中的下一项目
    next(value, collection) {
        // 数组类型集合
        if (Array.isArray(collection)) { return value < collection.length - 1 ? value + 1 : 0 }
        // 字典类型集合
        else {
            collection = Object.keys(collection)
            const index = collection.indexOf(value)
            return index < 0 || index === collection.length - 1 ? collection[0] : collection[index + 1]
        }
    },

    // 数组或字符串随机乱序
    shuffle(object) {
        const string = typeof object === 'string'
        if (string) { object = object.split('') }
        for (let i = object.length - 1; i > 0; i--) {
            let j = Math.floor(Math.random() * (i + 1))
                ;[object[i], object[j]] = [object[j], object[i]]
        }
        if (string) { object = object.join('') }
        return object
    },
}

export { data }
