export const config = ($t, region) => {
    return {
        // 关于
        about: {
            app: 'passwords', // 类名
            name: $t('app.name'), // 显示名称
            id: 'team.vvip.passwords', // package id
            version: 'v1.0.0-alpha.1', // 版本
            url: 'https://passwords.vvip.team/', // PWA 网址

            // 发布日期 (注意月份是从 0 到 11)
            date: new Intl.DateTimeFormat(region, { dateStyle: 'medium' }).format(new Date(2022, 1, 8)),
        },

        statusbar: {
            color: ['#006a5e', '#87b1ac'], // 状态栏背景色 [亮色主题, 暗色主题]
            overlay: false, // 是否将App内容覆盖到状态条
        },

        // 欢迎页面
        welcome: {
            // 用户许可协议
            eula: {
                version: '20220208', // 版本
                resource: '/eula.html', // 资源页面路径
            },
            slides: [
                {
                    image: '/assets/images/welcome.png', // 主题图片
                    background: 'radial-gradient(#009688, #004D40)', // 背景颜色
                    color: '#B3FFFFFF', // 文字颜色
                },
            ],
        },

        // 相关应用
        related: {
            pwa: 30, // 安装超时时限描述
            route: '/versions', // 相关应用版本比较页面路径
        },

        exit: 2000, // 退出应用连按两次后退键的超时时限

        // 调试触发条件
        debug: {
            threshold: 5, // 连续点击次数
            timeout: 300, // 超时时限 (每次点击间隔时间)
        },

        fullscreen: false,
        insomnia: false,
        measure: true,
        scale: false,
        splash: true,

        passwords: {
            result: '#passwords-result-index-', // 密码列表结果ID前缀
        },
    }
}