export default {
    app: {
        name: 'Passwords',
        copyright: '© 2022 The VVIP Team 版權所有. 所有權利保留. ',
    },

    data: {
        generator: {
            label: '產生器',
            auto: {
                label: '自動產生',
                on: '啟動和設定變更時自動產生新密碼',
                off: '僅透過手動按鈕產生密碼',
            },
            quantity: {
                label: '密碼數量',
                placeholder: '每次產生的密碼數量',
                hint: '任何1到10之間的整數',
            },
        },
        presets: {
            name: {
                label: '預設名稱',
                placeholder: '任何你喜歡的名稱',
            },
            easy: '易於閱讀的強密碼', 
            lucky7: '包含幸運7的強密碼', 
            fortun8: '包含吉利8的強密碼', 
            symbols: '包含符號的加強密碼',
        },
        options: {
            upper: '大寫字母',
            lower: '小寫字母',
            numbers: '數字',
            symbols: '符號',
            similar: '相似字符 (0OIl)',
            ambiguous: '歧義字符 (如\\`\'")',
            length: {
                label: '密碼長度',
                placeholder: '產生的密碼字符數量',
                hint: '任何不超過1024的正整數',
            },
        },
        runtime: {
            name: {
                label: '@:data.presets.name.label',
                placeholder: '@:data.presets.name.placeholder',
            },
        },
    },

    passwords: {
        generate: '產生密碼',
        clear: '清空',
        unsaved: '(未儲存的設定)',
        presets: '預設',
        current: '當前預設',
        available: '可用預設',
        rename: '重新命名',
        delete: '移除',
        reset: '重置初始預設',
    },

    messages: {
        delete: '這項預設將被刪除. 該作業無法撤銷. 你確定要繼續嗎? ',
        reset: '對預設進行的所有客製化變更將被重設. 該作業無法撤銷. 你確定要繼續嗎? ',
        empty: '未選取任何用於產生密碼的字符',
        included: '已包含{group}',
        excluded: '已排除{group}',
        copied: '密碼已複製到剪貼簿',
    },
}