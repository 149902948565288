/* global vvip */

import { StatusBar } from '@capacitor/status-bar'

// 设置状态栏颜色
export const statusbar = (options) => {
    if (vvip.app.android) {
        if (options) { vvip.app.statusbar = options }
        StatusBar.setOverlaysWebView({ overlay: vvip.app.statusbar.overlay })
        StatusBar.setBackgroundColor({ color: vvip.app.statusbar.color[vvip.screen.dark ? 1 : 0] })
        StatusBar.setStyle({ style: vvip.screen.dark ? 'LIGHT' : 'DARK' })
    }
}