/* global vvip */

import { toastController } from '@ionic/vue'

// 提示信息 options: Object | String, args: color, position, duration
export const toast = async (options, ...args) => {
    const durations = { short: 2000, long: 5000, persistent: 0 } // 时长
    const positions = ['top', 'bottom', 'middle'] // 位置

    // 如果是字符串类型则合并参数
    if (typeof options === 'string') {
        // 加入默认持续时长
        options = { message: options, duration: durations.short }

        // 处理参数
        for (const arg of args) {
            if (vvip.ui.colors.includes(arg)) { options.color = arg }
            else if (positions.includes(arg)) { options.position = arg }
            else if (Object.keys(durations).includes(arg) || Number.isInteger(arg)) { options.duration = arg }
        }
    }

    // 处理显示时长
    if (Object.keys(durations).includes(options.duration)) { options.duration = durations[options.duration] }

    // 显示提示信息
    const toast = await toastController.create(options)
    await toast.present()
    return toast
}