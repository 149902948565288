<template>
  <ion-header @click="vvip.ui.modal.dismiss">
    <ion-toolbar>
      <ion-buttons slot="start">
        <ion-button>
          <i slot="icon-only" class="fa-fw fa-duotone fa-chevron-left"></i>
        </ion-button>
      </ion-buttons>
      <ion-title>{{ $t("passwords.presets") }}</ion-title>
      <ion-buttons slot="end">
        <ion-button>
          <i slot="icon-only" class="fa-duotone fa-xmark fa-xl"></i>
        </ion-button>
      </ion-buttons>
    </ion-toolbar>
  </ion-header>

  <ion-content fullscreen>
    <ion-list>
      <ion-item-divider class="ion-padding-end">
        <ion-text color="primary">{{ $t("passwords.current") }}</ion-text>
        <ion-text
          v-show="vvip.data.values.runtime.preset < 0"
          slot="end"
          color="primary"
          @click="passwords.presets.save"
        >
          <i class="fa-duotone fa-fw fa-lg fa-save fa-swap-opacity"></i>
        </ion-text>
        <ion-text
          v-show="vvip.data.values.runtime.preset >= 0"
          slot="end"
          color="primary"
          @click="passwords.presets.rename()"
        >
          <i class="fa-duotone fa-fw fa-lg fa-pen-to-square"></i>
        </ion-text>
      </ion-item-divider>
      <ion-item lines="none">
        <ion-label>{{
          vvip.data.values.runtime.preset >= 0
            ? vvip.data.values.presets[vvip.data.values.runtime.preset].name
            : $t("passwords.unsaved")
        }}</ion-label>
        <ion-text slot="start" color="primary">
          <i class="fa-duotone fa-fw fa-circle-dot"></i>
        </ion-text>
      </ion-item>
      <ion-item-divider
        class="ion-padding-end"
        @click="passwords.presets.manage"
      >
        <ion-text color="primary">{{ $t("passwords.available") }}</ion-text>
        <ion-text slot="end" color="primary">
          <i
            :class="[
              'fa-duotone',
              'fa-fw',
              'fa-lg',
              vvip.data.values.runtime.manage ? 'fa-check' : 'fa-gear',
            ]"
          ></i>
        </ion-text>
      </ion-item-divider>
      <ion-item
        v-show="!vvip.data.values.runtime.manage"
        v-for="(item, id) in vvip.data.values.presets"
        :key="id"
        lines="none"
        @click="passwords.presets.select(id)"
      >
        <ion-label
          :color="vvip.data.values.runtime.preset === id ? 'primary' : 'dark'"
        >
          {{ item.name }}
        </ion-label>
        <ion-text
          slot="start"
          :color="vvip.data.values.runtime.preset === id ? 'primary' : 'medium'"
        >
          <i
            :class="[
              'fa-duotone',
              'fa-fw',
              vvip.data.values.runtime.preset === id
                ? 'fa-circle-dot'
                : 'fa-circle',
            ]"
          ></i>
        </ion-text>
      </ion-item>
      <ion-reorder-group
        disabled="false"
        @ionItemReorder="passwords.presets.reorder($event.detail)"
        v-show="vvip.data.values.runtime.manage"
      >
        <ion-item
          v-for="(item, index) in vvip.data.values.presets"
          :key="index"
          lines="none"
        >
          <ion-label
            :color="
              vvip.data.values.runtime.preset === index ? 'primary' : 'dark'
            "
          >
            {{ item.name }}
          </ion-label>
          <ion-reorder slot="start">
            <ion-text color="primary">
              <i class="fa-duotone fa-fw fa-grip-lines"></i>
            </ion-text>
          </ion-reorder>
          <ion-text slot="end" :id="'passwords-presets-' + index">
            <i class="fa-duotone fa-fw fa-ellipsis"></i>
          </ion-text>
          <ion-popover
            :trigger="'passwords-presets-' + index"
            side="left"
            dismiss-on-select="true"
          >
            <ion-buttons>
              <ion-button
                color="primary"
                @click="passwords.presets.rename(index)"
              >
                {{ $t("passwords.rename") }}
                <i slot="start" class="fa-duotone fa-fw fa-edit"></i>
              </ion-button>
              <ion-button
                color="danger"
                @click="passwords.presets.delete(index)"
              >
                {{ $t("passwords.delete") }}
                <i slot="start" class="fa-duotone fa-fw fa-trash"></i>
              </ion-button>
            </ion-buttons>
          </ion-popover>
        </ion-item>
      </ion-reorder-group>
    </ion-list>
    <div class="ion-padding">
      <ion-button
        :color="vvip.data.values.generator.customized ? 'danger' : 'medium'"
        fill="outline"
        expand="block"
        @click="passwords.presets.reset"
      >
        {{ $t("passwords.reset") }}
        <i slot="start" class="fa-duotone fa-fw fa-rotate-left"></i>
      </ion-button>
    </div>
  </ion-content>
</template>

<script>
import {
  IonHeader,
  IonContent,
  IonToolbar,
  IonTitle,
  IonText,
  IonButtons,
  IonButton,
  IonList,
  IonItemDivider,
  IonItem,
  IonLabel,
  IonReorderGroup,
  IonReorder,
  IonPopover,
} from "@ionic/vue";

export default {
  name: "passwords-presets",
  components: {
    IonHeader,
    IonContent,
    IonToolbar,
    IonTitle,
    IonText,
    IonButtons,
    IonButton,
    IonList,
    IonItemDivider,
    IonItem,
    IonLabel,
    IonReorderGroup,
    IonReorder,
    IonPopover,
  },
  props: {},
  setup() {
    return {};
  },
};
</script>

<style scoped>
ion-content {
  --background: var(--ion-item-background, var(--ion-background-color, #fff));
  line-height: 1.5;
}

ion-item-divider {
  --background: transparent;
  border-bottom: none;
}

ion-item-divider:not(:first-child) {
  border-top: 1px solid
    var(
      --ion-item-border-color,
      var(--ion-border-color, var(--ion-color-step-150, rgba(0, 0, 0, 0.13)))
    );
  margin-top: 8px;
  --padding-top: 16px;
}

ion-popover {
  --offset-x: -16px;
  --offset-y: -4px;
}
</style>