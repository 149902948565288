import { modalController } from '@ionic/vue'

import vvip_modal from '../views/modal.vue'

// 全屏弹窗
const modal = async options => {
    const css = ['vvip-modal'] // 全屏弹框 CSS 样式

    // 与默认组件合并        
    options = Object.assign({ component: vvip_modal }, options)

    // 与默认样式合并
    if (options.cssClass) {
        options.cssClass = css.concat(Array.isArray(options.cssClass) ? options.cssClass : [options.cssClass])
    }
    else { options.cssClass = css }

    // 打开弹窗并加入弹窗列表
    const instance = await modalController.create(options)
    modal._instances.push(instance)
    await instance.present()
    return instance
}

// 全屏弹窗对象数组
modal._instances = []

// 关闭最后开启的弹窗
modal.dismiss = () => { if (modal._instances.length) { modal._instances.pop().dismiss() } }

// 关闭所有弹窗
modal.clear = () => {
    if (modal._instances.length) {
        modal.dismiss()
        modal.clear()
    }
}

export { modal }