import { NativeAudio } from '@ionic-native/native-audio'

// 声音
export const audio = {
    // 简单加载
    preload(id, assetPath, successCallback, errorCallback) {
        NativeAudio.preloadSimple(id, assetPath, successCallback, errorCallback)
    },

    // 复杂加载
    load(id, assetPath, volume, voices, delay, successCallback, errorCallback) {
        NativeAudio.preloadComplex(id, assetPath, volume, voices, delay, successCallback, errorCallback)
    },

    // 音量 
    volume(id, volume, successCallback, errorCallback) {
        NativeAudio.setVolumeForComplexAsset(id, volume, successCallback, errorCallback)
    },

    // 播放
    play(id, successCallback, errorCallback, completeCallback) {
        NativeAudio.play(id, successCallback, errorCallback, completeCallback)
    },
}