export default {
    vvip: {
        data: {
            app: {
                label: 'App Settings',
                locale: {
                    label: 'Language',
                    'auto': 'Auto',
                    'en-AU': 'English',
                    'zh-Hans': '简体中文',
                    'zh-Hant': '繁體中文',
                },
                dark: {
                    label: 'Dark theme',
                    off: 'Off',
                    auto: 'Auto',
                    on: 'On',
                },
            },
        },

        features: {
            header: 'Compare Versions',
            label: 'Features',
            platform: 'Platform supported',
            launch: {
                label: 'Launch from',
                browser: 'Browser',
                home: 'Home Screen',
            },
            offline: 'Work offline without Internet',
        },

        web: {
            label: 'Webpage',
        },

        pwa: {
            label: 'Web App',
            promotion: 'Add to home screen',
            installed: 'The immersive Web App (PWA) is already installed on your device. \n\nWould you like to launch it now? ',
            installing: [
                'Installing Web App (PWA)...',
                'Won\'t take long...',
                'Please stay tuned...',
                'Just a few more seconds...',
                'Almost there...',
                'Final approach...',
            ],
            timeout: 'Something is not right. It takes longer than usual to install the Web App. You may need to refresh the page and try again. ',
        },

        android: {
            label: 'Android App',
            installed: 'The full featured Android App is already installed on your device. \n\nWould you like to launch it now? ',
        },

        native: {
            promotion: 'Exclusive to Native App',
        },

        eula: {
            title: 'End-User License Agreement',
            first: {
                a: 'By continuing to use our app, you acknowledge that you consent to the ',
                b: '. ',
            },
            updated: {
                a: 'We have recently made some changes to the ',
                b: '. By continuing to use our app, you acknowledge that you consent to the updated agreement. ',
            },
            declined: 'You have chosen not to consent to the EULA. \n\nPlease close this window to exit. ',
        },

        exit: {
            will: 'Tap or swipe again to exit',
            did: 'Please close the window manually, if it is not terminated automatically. ',
        },

        common: {
            about: 'About',
            about_app: '@:vvip.common.about {app}',
            released: 'Released on {date}',
            ok: 'OK',
            cancel: 'Cancel',
            clear: 'Clear',
            open: 'Open',
            add: 'Add',
            get: 'Get',
            view: 'View',
            continue: 'Continue',
            consent: 'Consent and Continue',
            never: 'Never ask again',
            learnmore: 'Learn More',
            preferences: 'Preferences',
            exit: 'Exit App',
            debug: 'Debug',
        },

        messages: {
            select_or_input: 'Select from common presets or manually enter the value here',
            enter_valid_value: 'Please enter a valid value',
        },
    },
}