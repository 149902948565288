import { App } from '@capacitor/app'
import { getPlatforms } from '@ionic/vue'

import { reactive } from 'vue'
import { createI18n } from 'vue-i18n'

import { locales } from '../locales'

import { data } from './data'
import { debug } from './debug'
import { related } from './related'
import { storage } from './storage'
import { ui } from '../ui'
import { welcome } from './welcome'

import vvip_about from '../views/about.vue'

const platforms = getPlatforms()

const vvip = {
    config: reactive({}), // 系统配置

    // 平台
    platform: {
        platforms, // 平台列表
        android: platforms.includes('android'), // 是否Android平台
        ios: platforms.includes('ios'), // 是否iOS平台
        tablet: platforms.includes('tablet'), // 是否平板
        desktop: platforms.includes('desktop'), // 是否桌面平台
        system: navigator?.userAgentData?.platform || navigator?.platform || 'unknown', // 操作系统
        locale: navigator.language, // 语言
        dark: {}, // 暗色主题
    },

    screen: reactive({}), // 屏幕参数

    // 应用
    app: reactive({
        native: platforms.includes('hybrid'), // 是否原生应用
        installed: {}, // 已安装的相关应用
        statusbar: { color: ['#FFFFFF', '#000000'], overlay: false }, // 状态栏默认设置
    }),

    // 加载组件
    modules(id, module) { Object.assign(module ? vvip[module] : vvip, id) },

    // 初始化 - options
    // app: 系统app对象, global: 全局变量, locales: 多语言翻译, 
    // config: 配置文件, structure: 数据结构, data: 初始数据, computed: 自动计算的数据
    start(options) {
        // 全局对象
        window.vvip = vvip
        vvip.global = options.app.config.globalProperties
        vvip.global.vvip = vvip
        if (options.global) { for (const [k, v] of Object.entries(options.global)) { vvip.global[k] = v } }

        // 多语言
        options.app.use(createI18n(vvip.data.merge(locales, options.locales)))

        // 原生应用
        if (vvip.app.native) {
            for (const item of ['android', 'ios']) {
                if (vvip.platform[item]) {
                    vvip.app[item] = true
                    vvip.app.mode = item
                }
            }
        }
        // 网页或者网页应用 (PWA)
        else {
            vvip.app.pwa = window.matchMedia('(display-mode: fullscreen)').matches ||
                window.matchMedia('(display-mode: standalone)').matches || navigator.standalone ||
                window.matchMedia('(display-mode: minimal-ui)').matches // 网页应用 (PWA)
            vvip.app.web = !vvip.app.pwa // 网页 (浏览器)
            vvip.app.mode = vvip.app.pwa ? 'pwa' : 'web'
        }

        // 路由对象
        vvip.router = vvip.global.$router

        // 如果加载了相关应用组件且以网页模式运行则拦截网页应用的系统安装提示
        if (vvip.related && vvip.app.web) { vvip.related.intercept() }

        // 载入系统语言包的配置文件
        vvip.app.config = options.config
        Object.assign(vvip.config, vvip.app.config(vvip.global.$t, vvip.platform.locale))

        // 初始数据
        vvip.app.structure = options.structure
        Object.assign(vvip.data.structure, vvip.app.structure(vvip.global.$t))
        vvip.app.data = options.data
        vvip.data.init(options)

        // 返回多语言组件对象
        return vvip.global.$t
    },

    // 加载资料
    // { watch, locale, dark, statusbar, fullscreen, measure, scale, callback, splash, welcome, related }
    async mount(options = {}) {
        // 创建存储对象
        await vvip.storage.create(vvip.config.about.id)
        // 获取保存的数据并与默认值合并
        await vvip.data.load()
        vvip.data.watch(options)

        // 语言包
        vvip.ui.locale(vvip.data.values.app.locale)
        // 如果配置了暗色主题则监测并应用暗色主题
        vvip.ui.dark.monitor(vvip.data.values.app.dark)
        // 开启全屏模式
        if (vvip.data.values.app.fullscreen) { vvip.ui.fullscreen.on() }
        // 保持屏幕常亮
        if (vvip.data.values.app.insomnia) { vvip.ui.insomnia.on() }
        // 状态栏颜色
        vvip.ui.statusbar(vvip.config.statusbar)
        // 监视并测量屏幕参数
        if (vvip.config.measure) { vvip.ui.measure.monitor() }
        // 获取系统字体缩放比例
        if (vvip.config.scale) { vvip.ui.scale() }

        // 载入相应语言包的配置文件及数据结构
        Object.assign(vvip.config, vvip.app.config(vvip.global.$t, vvip.app.region))
        Object.assign(vvip.data.structure, vvip.app.structure(vvip.global.$t))

        // 整合配置文件和原生应用及网页网址中的关于信息
        if (vvip.app.native) {
            vvip.app.about = await App.getInfo()
            Object.assign(vvip.config.about, vvip.app.about)
        }
        else if (vvip.app.web) { vvip.config.about.url = window.location.origin }

        // 加载完成前的回调方法
        if (typeof options.callback === 'function') { options.callback() }

        // 关闭开始界面
        if (options.splash) { vvip.ui.splash.hide() }
        // 欢迎界面
        if (options.welcome) { vvip.welcome.check() }
        // 如果配置了相关应用则检查相关应用安装情况
        if (options.related) { vvip.related.check() }
    },

    // 关于
    about() { vvip.ui.modal({ component: vvip_about }) },

    // 执行调用
    call(fn) { if (typeof fn === 'function') { fn() } },

    // 延迟运行指定时间
    delay(ms) { return new Promise(fn => setTimeout(fn, ms)) },

    // 返回
    back() {
        // 如果存在上一页则退回上一页
        if (window.history.length > 1) { vvip.router.back() }
        // 否则转到首页
        else { vvip.router.replace('/') }
    },

    // 退出APP
    async exit() {
        const $t = vvip.global.$t
        // 如果已经在主界面
        if (window.location.pathname === '/') {
            // 如果即将退出标志位已开启则退出App
            if (vvip.app.exiting) { vvip.terminate($t('vvip.exit.did')) }
            // 否则
            else {
                // 提示再按一次退出App
                vvip.ui.toast($t('vvip.exit.will'))

                // 设置即将退出标志
                vvip.app.exiting = true
                await vvip.delay(vvip.config.app.exit)
                vvip.app.exiting = false
            }
        }
    },

    // 终止APP
    terminate() {
        const $t = vvip.global.$t
        // 如果是原生应用则退出
        if (vvip.app.native) { App.exitApp() }
        // 否则弹出提示框
        else { vvip.ui.alert($t('vvip.eula.declined').replaceAll('\n', '<br />')) }
    },

}

vvip.modules({ data, debug, related, storage, ui, welcome }) // 加载核心组件
export { vvip }