// 数据结构
/*
// 分类名称
[cat]: {
    instant: Boolean, // 是否即时数据 (不保存到 Storage)
    // 字段
    fields: {
        // 字段名称
        [key]: {
            label: String, // 显示名称
            placeholder: String, // 空值替换显示 (不同于手动输入框的占位符)
            readable: [ { value: String | Number, text: String }, ...], // 数值对应的直观含义
            number: Boolean, // 是否为数字类型 (强制类型转换)
            clear: Boolean, // 是否可清空已有值
            close: Boolean, // 是否在手动及选择输入后关闭输入界面
            multi: Boolean, // 是否多重数据 (数组)
        
            // 手动输入框 (ion-text)
            manual: { 
                type: String, // 类型
                mode: String, // 模式
                maxlength: Number, // 最大长度
                minlength: Number, // 最小长度
                max: Number, // 最大值
                min: Number, // 最小值
                pattern: Regex, // 正则校验
                autocapitalize: 'characters', // 软键盘自动大写
                placeholder: String, // 空值占位符
                hint: String, // 输入提示
            },
        
            // 开关 (ion-toggle)
            toggle: true, // 启用开关
        
            // 选择 (ion-chip)
            select: Array | Object, // 选项列表
        
            // 范围 (ion-range)
            range: {
                min: Number, // 最小值
                max: Number, // 最大值
                step: Number, // 步长
                snaps: Boolean, // 贴合步长
                start: String, // 开头标签
                end: String, // 结尾标签
                close: Boolean, // 是否在改动后关闭输入界面
            },
        },
        ...
    },
},
*/

export const structure = $t => {
    return {
        app: {
            fields: {
                eula: {},
                locale: {
                    label: $t('vvip.data.app.locale.label'),
                    icon: 'language',
                    close: true,
                    select: {
                        'auto': $t('vvip.data.app.locale.auto'),
                        'en-AU': $t('vvip.data.app.locale.en-AU'),
                        'zh-Hans': $t('vvip.data.app.locale.zh-Hans'),
                        'zh-Hant': $t('vvip.data.app.locale.zh-Hant')
                    },
                },
                dark:
                {
                    label: $t('vvip.data.app.dark.label'),
                    icon: 'moon-stars',
                    close: true,
                    select: {
                        'off': $t('vvip.data.app.dark.off'),
                        'auto': $t('vvip.data.app.dark.auto'),
                        'on': $t('vvip.data.app.dark.on'),
                    },
                },
            },
        },
        generator: {
            fields: {
                auto: {
                    label: $t('data.generator.auto.label'),
                    number: true,
                    toggle: true,
                    icon: 'bolt-lightning',
                },
                quantity: {
                    label: $t('data.generator.quantity.label'),
                    icon: 'clone',
                    number: true,
                    close: true,
                    manual: {
                        type: 'number',
                        mode: 'numeric',
                        min: 1,
                        max: 10,
                        maxlength: 2,
                        pattern: /^[0-9]+$/,
                        placeholder: $t('data.generator.quantity.placeholder'),
                        hint: $t('data.generator.quantity.hint'),
                    },
                    range: {
                        min: 1,
                        max: 10,
                        step: 1,
                        snaps: true,
                        start: '1',
                        end: '10',
                        close: true,
                    },
                },
                customized: {}, // 是否自定义过预设选项
            },
        },
        presets: {
            multi: true,
            fields: {
                name: {
                    label: $t('data.presets.name.label'),
                    manual: {
                        id: 'passwords-input-presets-name',
                        name: 'name',
                        type: 'text',
                        placeholder: $t('data.presets.name.placeholder'),
                        minlength: 1,
                    },
                },
                options: {},
            },
        },
        options: {
            fields: {
                upper: { label: 'ABCD' }, // 大写 (0: 不可用, 1: 全部可用, 2: 部分可用)
                lower: { label: 'abcd' }, // 小写 (0: 不可用, 1: 全部可用, 2: 部分可用)
                numbers: { label: '1234' }, // 数字 (0: 不可用, 1: 全部可用, 2: 部分可用)
                symbols: { label: '!@#$' }, // 符号 (0: 不可用, 1: 全部可用, 2: 部分可用)
                similar: { label: '0OIl' }, // 相似 (-2: 不可用且不适用, -1: 可用但不适用, 0: 不可用, 1: 可用)
                ambiguous: { label: '\\`\'"' }, // 歧义 (-2: 不可用且不适用, -1: 可用但不适用, 0: 不可用, 1: 可用)
                excluded: {}, // 排除字符
                compulsory: {}, // 必备字符
                length: {
                    label: $t('data.options.length.label'),
                    icon: 'ruler-horizontal',
                    number: true,
                    close: true,
                    manual: {
                        type: 'number',
                        mode: 'numeric',
                        min: 1,
                        max: 1024,
                        maxlength: 4,
                        pattern: /^[0-9]+$/,
                        placeholder: $t('data.options.length.placeholder'),
                        hint: $t('data.options.length.hint'),
                    },
                    range: {
                        min: 1,
                        max: 1024,
                        step: 1,
                        snaps: true,
                        start: '1',
                        end: '1024',
                    },
                    select: ['4', '6', '8', '12', '16', '24', '32', '64', '128', '256', '512', '1024'],
                },
            },
        },
        runtime: {
            instant: true,
            fields: {
                // 当前预设名称
                name: {
                    label: $t('data.runtime.name.label'),
                    manual: {
                        id: 'passwords-input-runtime-name',
                        name: 'name',
                        type: 'text',
                        placeholder: $t('data.runtime.name.placeholder'),
                        minlength: 1,
                    },
                },
                options: {}, // 解析后的选项
                preset: {}, // 当前选项对应的预设ID
                manage: {}, // 预设:编辑模式
                result: {}, // 生成的密码列表    
                busy: {}, // 是否正在生成
            },
        },

    }
}