<template>
  <ion-header v-if="title" @click="vvip.call(dismiss)">
    <ion-toolbar>
      <ion-buttons slot="start" v-if="dismiss">
        <ion-button>
          <i slot="icon-only" class="fa-fw fa-duotone fa-chevron-left"></i>
        </ion-button>
      </ion-buttons>
      <ion-title>{{ title }}</ion-title>
      <ion-buttons slot="end" v-if="dismiss">
        <ion-button>
          <i slot="icon-only" class="fa-duotone fa-xmark fa-xl"></i>
        </ion-button>
      </ion-buttons>
    </ion-toolbar>
  </ion-header>

  <ion-content fullscreen class="ion-padding">
    <ion-text v-if="html" v-html="content" color="medium"></ion-text>
    <ion-text v-else color="medium">{{ content }}</ion-text>
  </ion-content>

  <ion-footer v-if="ok || cancel">
    <ion-toolbar>
      <ion-buttons slot="start" v-if="cancel">
        <ion-button
          fill="outline"
          color="medium"
          @click="cancel.action"
          class="vvip-button-text"
          >{{ cancel.label }}</ion-button
        >
      </ion-buttons>
      <ion-buttons slot="end" v-if="ok">
        <ion-button
          fill="solid"
          color="primary"
          @click="ok.action"
          class="vvip-button-text"
          >{{ ok.label }}</ion-button
        >
      </ion-buttons>
    </ion-toolbar>
  </ion-footer>
</template>

<script>
import {
  IonHeader,
  IonFooter,
  IonContent,
  IonToolbar,
  IonTitle,
  IonText,
  IonButtons,
  IonButton,
} from "@ionic/vue";

export default {
  name: "vvip-modal",
  components: {
    IonHeader,
    IonFooter,
    IonContent,
    IonToolbar,
    IonTitle,
    IonText,
    IonButtons,
    IonButton,
  },
  props: {
    title: String, // 标题
    content: String, // 正文
    html: Boolean, // 正文是否HTML内容
    ok: Object, // 确认按钮
    cancel: Object, // 取消按钮
    dismiss: Function, // 关闭按钮调用方法
  },
  setup() {
    return {};
  },
};
</script>

<style scoped>
ion-content {
  --background: var(--ion-item-background, var(--ion-background-color, #fff));
  line-height: 1.5;
}

ion-footer {
  padding: 0 8px;
}
</style>