<template>
  <ion-header @click="vvip.ui.modal.dismiss">
    <ion-toolbar>
      <ion-buttons slot="start">
        <ion-button>
          <i slot="icon-only" class="fa-fw fa-duotone fa-chevron-left"></i>
        </ion-button>
      </ion-buttons>
      <ion-title>{{ data.structure.label }}</ion-title>
      <ion-buttons slot="end">
        <ion-button
          v-if="data.structure.clear && data.pending"
          color="medium"
          @click="vvip.data.commit('', 'clear')"
        >
          {{ $t("vvip.common.clear") }}
        </ion-button>
        <ion-button>
          <i slot="icon-only" class="fa-duotone fa-xmark fa-xl"></i>
        </ion-button>
      </ion-buttons>
    </ion-toolbar>
  </ion-header>

  <ion-content fullscreen class="ion-padding">
    <!-- 手动 -->
    <div lines="none" v-if="data.structure.manual">
      <ion-input
        id="vvip-editor-manual"
        :value="data.manual"
        :class="{ 'vvip-editor-invalid': data.invalid }"
        @ionChange="vvip.data.manual($event.detail.value)"
        @ionBlur="vvip.data.commit($event.target.value, 'manual')"
        @keyup.enter="vvip.data.commit()"
        :type="data.structure.manual.type"
        :inputmode="data.structure.manual.mode"
        :max="data.structure.manual.max"
        :min="data.structure.manual.min"
        :maxlength="data.structure.manual.maxlength"
        :minlength="data.structure.manual.minlength"
        :placeholder="data.structure.manual.placeholder"
        :autocapitalize="data.structure.manual.autocapitalize"
        enterkeyhint="done"
        autofocus
      ></ion-input>
    </div>

    <!-- 手动: 提示 -->
    <div lines="none" id="vvip-editor-hint" v-if="data.structure.manual">
      <ion-text>
        {{ data.invalid ? data.structure.manual.hint : "&nbsp;" }}
      </ion-text>
    </div>

    <!-- 范围 -->
    <div lines="none" v-if="data.structure.range">
      <ion-range
        :value="data.range"
        :min="data.structure.range.min"
        :max="data.structure.range.max"
        :step="data.structure.range.step"
        :snaps="data.structure.range.snaps"
        @ionChange="vvip.data.commit($event.detail.value, 'range')"
      >
        <ion-label slot="start" v-if="data.structure.range.start">{{
          data.structure.range.start
        }}</ion-label>
        <ion-label slot="end" v-if="data.structure.range.end">{{
          data.structure.range.end
        }}</ion-label>
      </ion-range>
    </div>

    <!-- 选择 -->
    <div lines="none" v-if="data.structure.select">
      <ion-chip
        v-for="(value, key) in data.structure.select"
        :key="key"
        :class="{
          'vvip-chip-selected':
            (Array.isArray(data.structure.select) ? value : key) ===
            data.pending,
        }"
        @click="
          vvip.data.commit(
            Array.isArray(data.structure.select) ? value : key,
            'select'
          )
        "
      >
        <ion-label>{{ value }}</ion-label>
      </ion-chip>
    </div>
  </ion-content>
</template>

<script>
import {
  IonHeader,
  IonContent,
  IonToolbar,
  IonTitle,
  IonText,
  IonLabel,
  IonInput,
  IonButtons,
  IonButton,
  IonChip,
  IonRange,
} from "@ionic/vue";

export default {
  name: "vvip-editor",
  components: {
    IonHeader,
    IonContent,
    IonToolbar,
    IonTitle,
    IonText,
    IonLabel,
    IonInput,
    IonButtons,
    IonButton,
    IonChip,
    IonRange,
  },
  props: {
    data: Object,
  },
  setup() {
    return {};
  },
};
</script>

<style scoped>
ion-content {
  --background: var(--ion-item-background, var(--ion-background-color, #fff));
}

ion-input {
  border: 1px solid var(--ion-color-medium);
  --background: var(--ion-color-light);
  --padding-start: 8px !important;
  --padding-end: 8px !important;
}

ion-input.vvip-editor-invalid {
  border: 1px solid var(--ion-color-danger);
}

ion-chip.vvip-chip-selected {
  color: var(--ion-color-light);
  background: var(--ion-color-primary);
}

#vvip-editor-hint {
  font-size: 0.875rem;
  font-style: italic;
  color: var(--ion-color-medium);
  padding: 8px 0;
}
</style>