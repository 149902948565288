import { vvip } from './vvip' // VVIP 代码库
import { config } from './config' // 配置
import { structure } from './structure' // 数据结构
import { data } from './data' // 初始数据
import { locales } from '../locales' // 多语言翻译

import presets from '../views/presets.vue'

let $t

const passwords = {

    // 初始化
    start(app) {
        // 初始化 - VVIP 
        // app: 系统app对象, global: 全局变量, locales: 多语言翻译, 
        // config: 配置文件, structure: 数据结构, data: 初始数据, computed: 自动计算的数据
        $t = vvip.start({ app, global: { passwords }, locales, config, structure, data })
    },

    // 加载数据
    async mount() {
        // watch: 监控数据, callback: 加载完成前的回调方法
        await vvip.mount({
            splash: true,
            welcome: true,
            related: true,
            async callback() {
                // 等待DOM渲染完成 (nextTick 无效)
                await vvip.delay(1000)

                // 占位元素 (竖屏模式在密码列表末尾留出选项设置的空间)
                vvip.ui.placeholder.set({
                    target: '#passwords-result',
                    reference: '#passwords-settings',
                    style: 'padding-bottom',
                    criteria() { return vvip.screen.portrait },
                })

                // 解析生成密码选项
                passwords.parse()
            },
        })
    },

    // 切换选项
    toggle: {
        // 开启关闭备选字符组别
        group(group) {
            if (!vvip.data.values.runtime.busy) {
                // 开启关闭备选字符组别
                vvip.data.toggle('options', group)
                // 解析生成密码选项
                passwords.parse(group)
            }
        },

        upper() { passwords.toggle.group('upper') },
        lower() { passwords.toggle.group('lower') },
        numbers() { passwords.toggle.group('numbers') },
        symbols() { passwords.toggle.group('symbols') },
        similar() { passwords.toggle.group('similar') },
        ambiguous() { passwords.toggle.group('ambiguous') },

        auto() {
            vvip.data.toggle('generator', 'auto', {
                callback: () => {
                    vvip.ui.toast($t('data.generator.auto.' + (vvip.data.values.generator.auto ? 'on' : 'off')))
                }
            })
        }
    },

    // 下一选项
    shift: {
        // 暗色主题
        dark() {
            vvip.ui.menu.close()
            vvip.data.shift('app', 'dark')
            vvip.ui.dark(vvip.data.values.app.dark)
        },
    },

    // 选择选项
    select: {
        // 暗色主题
        dark(value, event) {
            vvip.ui.menu.close()
            vvip.data.select('app', 'dark', value, { event })
            vvip.ui.dark(vvip.data.values.app.dark)
        },
    },

    // 弹出编辑数据窗口
    edit: {
        length() { vvip.data.edit('options', 'length', { callback: passwords.parse }) }, // 长度
        quantity() { vvip.data.edit('generator', 'quantity', { callback: passwords.auto }) }, // 数量

        // 语言
        locale() {
            vvip.data.edit('app', 'locale', {
                callback() {
                    vvip.ui.locale(vvip.data.values.app.locale)
                    vvip.ui.menu.close()
                },
            })
        },

        // 暗色主题
        dark() {
            vvip.data.edit('app', 'dark', {
                callback() {
                    vvip.ui.dark(vvip.data.values.app.dark)
                    vvip.ui.menu.close()
                },
            })
        },

    },

    // 预设
    presets: {
        // 显示界面
        present() {
            vvip.data.set('runtime', 'manage', 0) // 关闭管理模式
            vvip.ui.modal({ component: presets }) // 显示界面
        },

        // 切换管理模式
        manage() { vvip.data.toggle('runtime', 'manage') },

        // 选择预设
        select(id) {
            vvip.ui.modal.dismiss()
            // 复制预设内容后解析设置
            vvip.data.set('options', null, vvip.data.values.presets[id].options, { callback: passwords.parse })
        },

        // 重新排序
        reorder(detail) {
            detail.complete(vvip.data.values.presets)
            vvip.data.save('presets', { callback: passwords.parse })
            vvip.data.set('generator', 'customized', 1)
        },

        // 保存预设
        async save() {
            vvip.data.popup('runtime', 'name', {
                callback() {
                    vvip.data.insert('presets', 0, {
                        name: vvip.data.values.runtime.name,
                        options: vvip.data.values.options,
                    }, { callback: passwords.parse })
                    vvip.data.set('generator', 'customized', 1)
                },
            })
        },

        // 编辑预设
        async rename(index) {
            if (index === undefined) { index = vvip.data.values.runtime.preset }
            vvip.data.popup('presets', 'name', {
                index, callback() {
                    passwords.parse()
                    vvip.data.set('generator', 'customized', 1)
                },
            })
        },

        // 删除预设
        async delete(index) {
            await vvip.ui.alert({
                header: vvip.data.values.presets[index].name,
                message: $t('messages.delete'),
                buttons: [
                    {
                        text: $t('vvip.common.cancel'),
                        role: 'cancel',
                    },
                    {
                        text: $t('vvip.common.ok'),
                        role: 'ok',
                        handler() {
                            vvip.data.delete('presets', index, { callback: passwords.parse })
                            vvip.data.set('generator', 'customized', 1)
                        },
                    }
                ]
            })
        },

        // 还原预设
        async reset() {
            await vvip.ui.alert({
                header: $t('passwords.reset'),
                message: $t('messages.reset'),
                buttons: [
                    {
                        text: $t('vvip.common.cancel'),
                        role: 'cancel',
                    },
                    {
                        text: $t('vvip.common.ok'),
                        role: 'ok',
                        handler() {
                            vvip.data.set('presets', null, vvip.app.data($t).presets, { callback: passwords.parse })
                            vvip.data.set('runtime', 'manage', 0) // 关闭管理模式
                            vvip.data.set('generator', 'customized', 0)
                        },
                    }
                ]
            })
        },
    },

    // 解析生成密码选项
    parse(group) {
        // 比对预设选项
        const items = Object.entries(vvip.data.values.options)
        vvip.data.set('runtime', 'preset',
            vvip.data.values.presets.findIndex(preset => items.every(([key, value]) => value === preset.options[key]))
        )

        // 解析生成密码选项
        vvip.data.set('runtime', 'options', vvip.random.parse(vvip.data.values.options))

        // 选项改变提示
        if (vvip.data.values.runtime.options.pool.length) {
            if (group) {
                vvip.ui.toast($t('messages.' + (vvip.data.values.runtime.options[group] ? 'included' : 'excluded'), {
                    group: $t('data.options.' + group)
                }), vvip.data.values.runtime.options[group] ? 'success' : 'warning')
            }
            // 如果指定了自动模式 则即时生成密码
            passwords.auto()
        }
        else { vvip.ui.toast($t('messages.empty'), 'danger') }

    },

    // 如果指定了自动模式 则即时生成密码
    auto() { if (vvip.data.values.generator.auto) { passwords.generate() } },

    // 生成密码
    async generate() {
        if (!vvip.data.values.runtime.busy) {
            if (vvip.data.values.runtime.options.pool.length) {
                vvip.data.values.runtime.busy = true

                // 生成密码
                vvip.data.set('runtime', 'result', vvip.random.generate({
                    quantity: vvip.data.values.generator.quantity,
                    length: vvip.data.values.runtime.options.length,
                    pool: vvip.data.values.runtime.options.pool,
                    compulsory: vvip.data.values.runtime.options.compulsory,
                }))

                // 等待DOM更新
                await vvip.ui.render()

                // 乱码效果 (i = n to 1)
                for (let i = vvip.data.values.runtime.result.length - 1; i > 0; i--) {
                    vvip.shuffle.create({
                        element: vvip.config.passwords.result + i,
                        text: vvip.data.values.runtime.result[i],
                        pool: vvip.data.values.runtime.options.pool,
                    })
                }
                // 乱码效果 (i = 0, 完成后重置正在运行标志)
                vvip.shuffle.create({
                    element: vvip.config.passwords.result + 0,
                    text: vvip.data.values.runtime.result[0],
                    pool: vvip.data.values.runtime.options.pool,
                    callback() { vvip.data.values.runtime.busy = false },
                })
            }
            else { vvip.ui.toast($t('messages.empty'), 'danger') }
        }
    },

    // 复制到剪贴板
    copy(index) {
        vvip.data.copy(vvip.data.values.runtime.result[index])
        if (!vvip.platform.android) { vvip.ui.toast($t('messages.copied')) }
    },

    // 清空结果
    clear() { vvip.data.set('runtime', 'result', []) },

    // 关于
    about() {
        vvip.ui.menu.close()
        vvip.about()
    },

    // 测试
    debug() {
        vvip.ui.menu.close()
        vvip.debug({
            about: vvip.config.about,
            data: vvip.data.values,
            session: vvip.data.session,
            structure: vvip.data.structure,
            config: vvip.config,
            platform: vvip.platform,
            screen: vvip.screen,
            app: vvip.app,
        })
    },

}

export { passwords }