<template>
  <ion-button
    :disabled="data.status < 0"
    :fill="data.fill ? data.fill : 'outline'"
    :color="
      data.toggle
        ? data.status < 0
          ? 'medium'
          : data.status
          ? data.status === 1
            ? 'primary'
            : 'secondary'
          : 'medium'
        : data.color
    "
    :expand="data.expand === undefined ? 'block' : data.expand"
    :class="{
      'vvip-case': data.case,
      'vvip-mono': data.mono,
      'vvip-condensed': true,
    }"
    @click="vvip.call(data.click)"
  >
    <span class="vvip-left"><slot></slot></span>
    <i
      slot="end"
      :class="[
        'fa-fw',
        'fa-duotone',
        data.toggle
          ? data.status < 0
            ? 'fa-ban'
            : data.status
            ? data.status === 1
              ? 'fa-check'
              : 'fa-asterisk'
            : 'fa-minus'
          : 'fa-' + data.icon,
      ]"
    ></i>
  </ion-button>
</template>

<script>
import { IonButton } from "@ionic/vue";

export default {
  name: "vvip-switch",
  components: {
    IonButton,
  },
  props: {
    data: Object,
  },
  setup() {
    return {};
  },
};
</script>

<style scoped>
</style>