export default {
    vvip: {
        data: {
            app: {
                label: 'APP 设置',
                locale: {
                    label: 'Language',
                    'auto': '自动',
                    'en-AU': 'English',
                    'zh-Hans': '简体中文',
                    'zh-Hant': '繁體中文',
                },
                dark: {
                    label: '暗色主题',
                    off: '关闭',
                    auto: '自动',
                    on: '开启',
                },
            },
        },

        features: {
            header: '版本对比',
            label: '功能特性',
            platform: '支持平台',
            launch: {
                label: '打开方式',
                browser: '浏览器',
                home: '首屏',
            },
            offline: '无需互联网脱机工作',
        },

        web: {
            label: '网页',
        },

        pwa: {
            label: '网页应用',
            promotion: '将本应用添加到首屏',
            installed: '已经在此设备上安装了沉浸式的网页应用 (PWA). \n\n你想要现在打开它吗? ',
            installing: [
                '正在安装网页应用...',
                '不会太久喔...',
                '请稍等片刻...',
                '只需再多几秒钟...',
                '就快好了...',
                '最后一步...',
            ],
            timeout: '有点不对劲. 安装网页应用花了比平常更久的时间. 你可能需要刷新页面再试一次. ',
        },

        android: {
            label: '安卓 APP',
            installed: '已经在此设备安装了全功能的安卓 APP. \n\n你想要现在打开它吗? ',
        },

        native: {
            promotion: '仅适用于原生 APP',
        },

        eula: {
            title: '最终用户许可协议',
            first: {
                a: '继续使用本应用即表示你同意',
                b: '. ',
            },
            updated: {
                a: '最近我们对',
                b: '进行了一些改动. 继续使用本应用即表示你同意修改后的协议. ',
            },
            declined: '你已选择不同意用户协议. \n请关闭此窗口以退出. ',
        },

        exit: {
            will: '再按一次退出',
            did: '如未能自动终止, 请手动关闭窗口. ',
        },

        common: {
            about: '关于',
            about_app: '@:vvip.common.about{app}',
            released: '{date}发布',
            ok: '确定',
            cancel: '取消',
            clear: '清空',
            open: '打开',
            add: '添加',
            get: '下载',
            view: '查看',
            continue: '继续',
            consent: '同意并继续',
            never: '不再询问',
            learnmore: '了解更多',
            preferences: '设置',
            exit: '退出 APP',
            debug: '调试',
        },

        messages: {
            select_or_input: '从常用预设中选择, 或者在这里手动输入',
            enter_valid_value: '请输入符合条件的值',
        },
    },
}