/* global vvip */

const { randCustomString } = require('rndmjs')

// 随机字符串
export const random = {
    _config: {
        // 毛字符组别 (包含相似及歧义字符)
        gross: {
            upper: 'ABCDEFGHIJKLMNOPQRSTUVWXYZ',
            lower: 'abcdefghijklmnopqrstuvwxyz',
            numbers: '0123456789',
            symbols: '!@#$%^&*-_=+|?{}[]()<>/\\`~,.;:\'"',
        },
        // 净字符组别 (去除相似及歧义字符)
        net: {
            upper: 'ABCDEFGHJKLMNPQRSTUVWXYZ',
            lower: 'abcdefghijkmnopqrstuvwxyz',
            numbers: '123456789',
            symbols: '!@#$%^&*-_=+|?',
        },

        // 特殊字符组别 (包含相似及歧义字符)
        special: {
            upper: 'IO',
            lower: 'l',
            numbers: '0',
            symbols: '{}[]()<>/\\`~,.;:\'"',
        },

        // 相似及歧义字符
        similar: '0OIl',
        ambiguous: '{}[]()<>/\\`~,.;:\'"',

        candidates: 'ABCDEFGHJKLMNPQRSTUVWXYZabcdefghijkmnopqrstuvwxyz123456789', // 默认字符池
    },

    // 解析选项
    parse(options) {
        const parsed = { ...options }
        parsed.pool = ''

        // 根据组别状态计算初步备选字符池
        for (const id of ['upper', 'lower', 'numbers']) {
            if (parsed[id]) { parsed.pool += random._config[parsed.similar ? 'gross' : 'net'][id] }
        }
        if (parsed.symbols) { parsed.pool += random._config[parsed.ambiguous ? 'gross' : 'net'].symbols }

        // 去除排除字符
        for (const char of parsed.excluded.split('')) { parsed.pool = parsed.pool.replace(char, '') }

        // 遍历选中的组别 (包括全部选中及部分选中)
        for (const id of ['upper', 'lower', 'numbers', 'symbols']) {
            if (parsed[id]) {
                // 如果该组别的毛字符全部被排除则标记为未选中
                if (!random._config.gross[id].split('').some(char => parsed.pool.includes(char))) { parsed[id] = 0 }
                // 如果该组别的净字符全部被包含则标记为全部选中
                else if (random._config.net[id].split('').every(char => parsed.pool.includes(char))) { parsed[id] = 1 }
                // 否则标记为部分选中
                else { parsed[id] = 2 }
            }
        }

        // 相似及歧义组别
        for (const id of ['similar', 'ambiguous']) {
            if (parsed[id]) {
                // 如果该组别的字符全部包含在排除字符中则标记为未选中
                if (random._config[id].split('').every(char => parsed.excluded.includes(char))) { parsed[id] = 0 }
                // 如果该组别的字符部分包含在排除字符中则标记为部分选中
                else if (random._config[id].split('').some(char => parsed.excluded.includes(char))) { parsed[id] = 2 }
                // 否则标记为全部选中
                else { parsed[id] = 1 }
            }
        }

        // 如果未选中任何大写小写及数字 则相似字符不适用
        if (!(parsed.upper || parsed.lower || parsed.numbers)) { parsed.similar = -1 }
        // 如果未选中任何符号 则相似字符不适用
        if (!parsed.symbols) { parsed.ambiguous = -1 }

        // 返回值
        return parsed
    },

    // 生成随机字符串 (quantity, length, pool, compulsory)
    generate(options) {
        const result = [] // 结果数组
        const initial = options.compulsory ?? '' // 初始值 (全部必备字符)
        const extra = options.length - options.compulsory.length // 除去必备字符以外所需的额外长度

        // 生成指定数量的字符串
        Array.from(Array(options.quantity)).map(() => {
            // 初始值 (全部必备字符)
            let generated = initial
            // 如果需要额外长度则适用字符池中的字符随机生成
            if (extra > 0) { generated += randCustomString(options.pool, extra) }
            // 随机乱序
            generated = vvip.data.shuffle(generated)
            // 如果所需长度小于必备字符则只取需要的字符数量
            if (extra < 0) { generated = generated.slice(0, length) }
            // 加入结果数组
            result.push(generated)
        })
        return result
    },

}
