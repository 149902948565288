<template>
  <ion-content
    :style="
      vvip.config.welcome.slides[id].background
        ? '--background: ' + vvip.config.welcome.slides[id].background
        : ''
    "
  >
    <div
      id="vvip-welcome-container"
      :class="{
        'vvip-welcome-horizontal':
          vvip.screen.landscape && !vvip.platform.tablet,
      }"
    >
      <div id="vvip-welcome-image">
        <img :src="vvip.config.welcome.slides[id].image" />
      </div>
      <div id="vvip-welcome-actions" class="ion-padding ion-text-center">
        <div id="vvip-welcome-actions-contents">
          <div
            :style="
              vvip.config.welcome.slides[id].color
                ? 'color: ' + vvip.config.welcome.slides[id].color
                : ''
            "
            class="vvip-welcome-fineprint ion-padding-bottom"
            @click="vvip.welcome.eula"
          >
            <p>
              {{
                $t(
                  "vvip.eula." +
                    (vvip.data.values.app.eula.length ? "updated" : "first") +
                    ".a"
                )
              }}<u>{{ $t("vvip.eula.title") }}</u
              >{{
                $t(
                  "vvip.eula." +
                    (vvip.data.values.app.eula.length ? "updated" : "first") +
                    ".b"
                )
              }}
            </p>
          </div>
          <div class="ion-padding-top">
            <ion-button
              fill="solid"
              color="primary"
              expand="block"
              @click="vvip.welcome.consent"
              >{{ $t("vvip.common.continue") }}</ion-button
            >
          </div>
          <div>
            <ion-button
              fill="clear"
              color="medium"
              @click="vvip.welcome.decline"
              >{{ $t("vvip.common.exit") }}</ion-button
            >
          </div>
        </div>
      </div>
    </div>
  </ion-content>
</template>

<script>
import { IonContent, IonButton } from "@ionic/vue";

export default {
  name: "vvip-welcome",
  data() {
    return {
      id: 0,
    };
  },
  components: {
    IonContent,
    IonButton,
  },
  setup() {
    return {};
  },
};
</script>

<style scoped>
ion-content {
  --background: var(--ion-item-background, var(--ion-background-color, #fff));
  line-height: 1.5;
}

#vvip-welcome-container {
  display: flex;
  height: 100%;
  flex-flow: column;
}

#vvip-welcome-container.vvip-welcome-horizontal {
  flex-flow: row;
}

#vvip-welcome-image {
  flex-grow: 1;
  overflow: hidden;
}

.vvip-welcome-horizontal #vvip-welcome-image,
.vvip-welcome-horizontal #vvip-welcome-actions {
  width: 50%;
}

#vvip-welcome-actions-contents {
  position: relative;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

#vvip-welcome-image img {
  max-width: 100%;
  max-height: 100%;
  position: relative;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.vvip-welcome-fineprint {
  font-size: 0.75rem;
}
</style>