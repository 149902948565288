/* global vvip */

import { toRaw, isReactive } from 'vue'
import { Storage, Drivers } from '@ionic/storage'

// 本地存储
export const storage = {
    _config: {
        name: 'team.vvip', // 默认存储对象名称
    },

    // 存储对象
    _data: {},

    // 创建存储对象
    async create(options = {}) {
        vvip.storage._data = new Storage(Object.assign({
            name: vvip.storage._config.name,
            driverOrder: [Drivers.IndexedDB, Drivers.LocalStorage],
        }, typeof options === 'string' ? { name: options } : options))
        await vvip.storage._data.create()
    },

    // 保存数据
    async set(key, value) { await vvip.storage._data.set(key, isReactive(value) ? toRaw(value) : value) },

    // 获取指定键值的数据
    async get(key) { return await vvip.storage._data.get(key) },

    // 删除键值
    async remove(key) { await vvip.storage._data.remove(key) },

    // 清空所有数据
    async clear() { await vvip.storage._data.clear() },

    // 获取键值列表
    async keys() { return await vvip.storage._data.keys() },

    // 获取保存数据数量
    async length() { return await vvip.storage._data.length() },
}