<template>
  <ion-header @click="vvip.ui.modal.dismiss">
    <ion-toolbar>
      <ion-buttons slot="start">
        <ion-button>
          <i slot="icon-only" class="fa-fw fa-duotone fa-chevron-left"></i>
        </ion-button>
      </ion-buttons>
      <ion-title>{{ $t("vvip.common.about") }}</ion-title>
      <ion-buttons slot="end">
        <ion-button>
          <i slot="icon-only" class="fa-duotone fa-xmark fa-xl"></i>
        </ion-button>
      </ion-buttons>
    </ion-toolbar>
  </ion-header>

  <ion-content fullscreen class="ion-padding">
    <div
      id="vvip-about-container"
      :class="{
        'vvip-about-landscape': vvip.screen.landscape && !vvip.screen.large,
      }"
    >
      <div id="vvip-about-logo">
        <div id="vvip-about-logo-contents">
          <div class="ion-padding-vertical">
            <img src="/assets/icon/icon.png" @click="vvip.debug.count" />
          </div>
          <div id="vvip-about-name-portrait" class="ion-padding-vertical">
            <h3>
              <ion-text color="primary">
                <b>{{ vvip.config.about.name }}</b>
              </ion-text>
            </h3>
            <div>
              {{ vvip.config.about.version + " [" + vvip.app.mode + "]" }}
            </div>
            <div class="vvip-about-fineprint">
              <ion-text color="medium">
                {{
                  $t("vvip.common.released", { date: vvip.config.about.date })
                }}
              </ion-text>
            </div>
          </div>
        </div>
      </div>
      <div id="vvip-about-details" class="ion-padding">
        <div id="vvip-about-details-contents">
          <div id="vvip-about-name-landscape" class="ion-padding-bottom">
            <h3>
              <ion-text color="primary">
                <b>{{ vvip.config.about.name }}</b>
              </ion-text>
            </h3>
            <div>
              {{ vvip.config.about.version + " [" + vvip.app.mode + "]" }}
            </div>
            <div class="vvip-about-fineprint">
              <ion-text color="medium">
                {{
                  $t("vvip.common.released", { date: vvip.config.about.date })
                }}
              </ion-text>
            </div>
          </div>
          <div class="vvip-about-fineprint ion-padding-top">
            <div>
              <ion-text color="primary" @click="vvip.welcome.eula">
                {{ $t("vvip.eula.title") }}
              </ion-text>
            </div>
            <div>
              <ion-text color="medium">
                {{ $t("app.copyright") }}
              </ion-text>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ion-content>
</template>

<script>
import {
  IonToolbar,
  IonHeader,
  IonContent,
  IonTitle,
  IonText,
  IonButtons,
  IonButton,
} from "@ionic/vue";

export default {
  name: "vvip-about",
  components: {
    IonToolbar,
    IonHeader,
    IonContent,
    IonTitle,
    IonText,
    IonButtons,
    IonButton,
  },
  setup() {
    return {};
  },
};
</script>

<style scoped>
ion-content {
  --background: var(--ion-item-background, var(--ion-background-color, #fff));
  line-height: 1.5;
}

#vvip-about-container {
  display: flex;
  height: 100%;
  flex-flow: column;
  margin: auto;
}

#vvip-about-container.vvip-about-landscape {
  flex-flow: row;
}

#vvip-about-logo {
  flex-grow: 1;
  overflow: hidden;
}

#vvip-about-container.vvip-about-landscape {
  width: 70%;
}

.vvip-about-landscape #vvip-about-logo {
  width: 40%;
}

.vvip-about-landscape #vvip-about-details {
  width: 60%;
}

#vvip-about-logo-contents,
#vvip-about-details-contents {
  position: relative;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
}

#vvip-about-logo img {
  max-width: 25%;
  max-height: 25%;
}

.vvip-about-landscape #vvip-about-logo img {
  max-width: 40%;
  max-height: 40%;
}

#vvip-about-name-landscape {
  display: none;
}

.vvip-about-landscape #vvip-about-name-landscape {
  display: block;
}

.vvip-about-landscape #vvip-about-name-portrait {
  display: none;
}

.vvip-about-fineprint {
  font-size: 0.75rem;
}
</style>