/* global vvip */

// 调试
const debug = (data) => {
    const $t = vvip.global.$t
    vvip.ui.modal({
        componentProps: {
            title: $t('vvip.common.debug'),
            content: '<pre>' + JSON.stringify(data, null, 2) + '</pre>',
            html: true,
            dismiss: vvip.ui.modal.dismiss,
        },
        cssClass: debug._css,
    })
}

debug._timer = null // 计时器
debug._counter = 0 // 计数器
debug._css = 'vvip-debug' // 测试 CSS 样式

// 计数 (指定时间内连续点击n次弹出调试界面)
debug.count = () => {
    // 清除计时
    if (debug._timer) { clearTimeout(debug._timer) }
    // 递增计数器
    debug._counter++
    // 如果计数达到触发门槛则执行调试操作
    if (debug._counter >= vvip.config.debug.threshold) { vvip.global[vvip.config.about.app].debug() }
    // 在超时时限后将计数器清零
    debug._timer = setTimeout(() => debug._counter = 0, vvip.config.debug.timeout)
}

export { debug }

