import { alertController } from '@ionic/vue'

// 确认框
export const alert = async options => {
    if (typeof options === 'string') { options = { message: options } }
    const alert = await alertController.create(options)
    await alert.present()
    return alert
}

