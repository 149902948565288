// 改编自 https://github.com/ics-ikeda/shuffle-text/blob/main/build/shuffle-text.js

// 乱码效果 { element: 目标DOM元素, text: 显示内容, empty: 起始占位符, pool: 备选字符池, duration: 效果时长 }
const shuffle = function (options) {
    this.pool = options.pool ?? 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789' // 乱码字符池
    this.empty = options.empty ?? '*' // 起始占位符
    this.duration = options.duration ?? 600 // 乱码效果时长

    this.callback = options.callback // 效果完成后的回调方法
    this.element = document.querySelector(options.element) // DOM 元素
    this.text = options.text // 原始文本

    this._length = options.text.length // 文本长度
    this._time = 0 // 开始运行时间
    this._random = [] // 每个字符的乱码效果随机比例
    this._animation = 0 // 动画控制对象

    this.start() // 开始乱码效果
}

// 创建乱码效果
shuffle.create = options => new shuffle(options)

// 开始乱码效果
shuffle.prototype.start = function () {
    this._random = []
    let masked = ''

    // 遍历每一个字符生成随机效果时长 并用起始占位符设置初始效果
    for (var i = 0; i < this._length; i++) {
        var rate = i / this._length
        this._random[i] = Math.random() * (1 - rate) + rate
        masked += this.empty
    }
    // 记录开始时间
    this._time = new Date().getTime()
    // 执行下一帧乱码效果
    this._animation = requestAnimationFrame(() => this.next())
    // 更新到 DOM 元素
    this.element.textContent = masked
}

// 下一帧乱码效果
shuffle.prototype.next = function () {
    const progress = (new Date().getTime() - this._time) / this.duration // 效果进度
    let masked = ''

    // 遍历每一个字符
    for (var i = 0; i < this._length; i++) {
        // 如果当前字符的效果进度已达到则显示原始字符
        if (progress >= this._random[i]) { masked += this.text.charAt(i) }
        // 如果还未达到1/3则显示初始字符
        else if (progress < this._random[i] / 3) { masked += this.empty }
        // 否则从乱码字符池随机显示字符
        else { masked += this.pool.charAt(Math.floor(Math.random() * this.pool.length)) }
    }
    // 如果进度已完成
    if (progress > 1) { this.finish() }
    // 否则将乱码效果更新到 DOM 元素并执行下一帧乱码效果
    else {
        this.element.textContent = masked
        this._animation = requestAnimationFrame(() => this.next())
    }
}

// 完成乱码效果
shuffle.prototype.finish = function () {
    // 使用原始文本更新到 DOM 元素
    this.element.textContent = this.text
    // 执行回调方法
    if (typeof this.callback === 'function') { this.callback() }

    // 清理对象
    cancelAnimationFrame(this._animation)

    this.pool = ''
    this.empty = ''
    this.duration = 0

    this.callback = null
    this.element = null
    this.text = ''
    this._length = 0

    this._time = 0
    this._random = []
    this._animation = 0
}

export { shuffle }
