import en_AU from './en-AU'
import zh_Hans from './zh-Hans'
import zh_Hant from './zh-Hant'

export const locales = {
    messages: {
        'en-AU': en_AU,
        'zh-Hans': zh_Hans,
        'zh-Hant': zh_Hant,
    }
}