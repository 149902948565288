<template>
  <ion-item
    lines="none"
    @click="vvip.call(data.click)"
    :class="data.class"
    :disabled="disabled"
    :router-link="data.link"
    :detail="data.detail"
  >
    <ion-text slot="start" :color="data.structure.color">
      <i
        :class="[
          'fa-' + (data.structure.style ? data.structure.style : 'duotone'),
          'fa-fw',
          'fa-' + data.structure.icon,
        ]"
      ></i>
    </ion-text>
    <ion-label class="ion-text-wrap">
      {{ data.structure.label }}
      <template v-if="incompatible">
        <ion-text class="vvip-item-incompatible" color="warning">
          <i class="fa-duotone fa-fw fa-cube"></i>
        </ion-text>
      </template>
    </ion-label>

    <template v-if="typeof data.value !== 'undefined'">
      <!-- 按钮 -->
      <ion-button v-if="data.button" slot="end" size="default">
        <i
          v-if="data.button.icon"
          :slot="data.button.slot"
          :class="['fa-duotone', 'fa-fw', 'fa-' + data.button.icon]"
        ></i>
        {{ data.value }}
      </ion-button>

      <!-- 只读 -->
      <ion-chip
        v-else-if="data.readonly"
        slot="end"
        :class="'vvip-chip-selected'"
      >
        {{ data.value }}
      </ion-chip>

      <!-- 开关 -->
      <ion-toggle
        v-else-if="data.structure.toggle"
        slot="end"
        :checked="data.value && !disabled"
      ></ion-toggle>

      <!-- 短列表 (shortlist) -->
      <div v-else-if="data.structure.shortlist" slot="end">
        <ion-chip
          v-for="(item, key) in data.structure.select"
          :key="key"
          :class="{
            'vvip-chip-selected': data.value === key,
          }"
          @click="data.select(key, $event)"
          >{{ item }}
        </ion-chip>
      </div>

      <!-- 选择 -->
      <ion-chip
        v-else-if="data.structure.select || data.structure.range"
        slot="end"
        class="vvip-chip-selected"
      >
        {{
          data.structure.readable
            ? data.structure.readable.find(item => item.value === data.value)
                .text
            : Array.isArray(data.structure.select)
            ? data.value
            : data.structure.select[data.value]
        }}
        <i class="fa-duotone fa-chevron-down"></i>
      </ion-chip>
    </template>
  </ion-item>
</template>

<script>
import {
  IonItem,
  IonLabel,
  IonText,
  IonChip,
  IonToggle,
  IonButton,
} from "@ionic/vue";

export default {
  name: "vvip-item",
  components: {
    IonItem,
    IonLabel,
    IonText,
    IonChip,
    IonToggle,
    IonButton,
  },
  props: {
    data: Object,
  },
  computed: {
    // 只支持原生App但当前不是原生模式
    incompatible() {
      return this.data.native && !this.vvip.app.native;
    },

    // 指定禁用或者只支持原生App但当前不是原生模式
    disabled() {
      return this.data.disabled || this.incompatible;
    },
  },
  setup() {
    return {};
  },
};
</script>

<style scoped>
ion-chip.vvip-chip-selected {
  color: var(--ion-color-light);
  background: var(--ion-color-primary);
}

ion-chip ion-icon {
  color: var(--ion-color-light);
}

.ios div > ion-chip {
  margin: 0 0 0 8px;
}

.md ion-toggle {
  padding-right: 4px;
}

ion-item > ion-text {
  margin-inline-start: 8px;
  margin-inline-end: 24px;
}

ion-text.vvip-item-incompatible {
  padding-left: 4px;
}
</style>