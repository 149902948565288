/* global vvip */

// 暗色主题 - { theme: off 关闭 auto 跟随系统 on 开启 }
// vvip.device.dark: 系统全局设置, vvip.app.dark: 应用设置, vvip.screen.dark: 最终结果
const dark = theme => {
    // 配置
    const platform = 'vvip-dark-theme' // 设备全局暗色主题 CSS 样式
    const explicit = 'dark' // 直接指定暗色主题 CSS 样式

    // 如果指定了 mode 则记录
    if (theme) { vvip.app.dark = theme }

    // 计算是否启用暗色主题 - vvip.app.dark - auto: 自动模式跟随系统设置, off: 指定关闭, on: 指定启用
    vvip.screen.dark = vvip.app.dark === 'auto' ?
        vvip.platform.dark.matches || document.body.classList.contains(platform) : vvip.app.dark === 'on'

    // 设置页面CSS
    document.body.classList.toggle(explicit, vvip.screen.dark)

    // 如果设置了状态栏颜色则更新状态栏颜色
    if (vvip.app.statusbar) { vvip.ui.statusbar() }
}

// 监测系统暗色主题
dark.monitor = theme => {
    // 获取系统暗色主题设置
    vvip.platform.dark = window.matchMedia('(prefers-color-scheme: dark)')
    // 监测系统暗色主题设置变化
    vvip.platform.dark.addEventListener('change', vvip.ui.dark)
    // 应用暗色主题
    vvip.ui.dark(theme)
}

export { dark }