export default {
    app: {
        name: 'Passwords',
        copyright: '© Copyright 2022 The VVIP Team. All rights reserved. ',
    },

    data: {
        generator: {
            label: 'Generator',
            auto: {
                label: 'Auto generate',
                on: 'New passwords will be generated on startup and when settings updated',
                off: 'Passwords only to be generated manually',
            },
            quantity: {
                label: 'Password Quantity',
                placeholder: 'Number of passwords to be generated at a time',
                hint: 'Any whole number between 1 and 10',
            },
        },
        presets: {
            name: {
                label: 'Preset Name',
                placeholder: 'Any name you like',
            },
            easy: 'Strong and Easy to Read', 
            lucky7: 'Strong with Lucky 7', 
            fortun8: 'Strong and Fortun8', 
            symbols: 'Extra Strong with Symbols',
        },
        options: {
            upper: 'Uppercase letters',
            lower: 'Lowercase letters',
            numbers: 'Numbers',
            symbols: 'Symbols',
            similar: 'Similar characters (0OIl)',
            ambiguous: 'Ambiguous characters (e.g. \\`\'")',
            length: {
                label: 'Password Length',
                placeholder: 'Number of characters to be generated',
                hint: 'Any positive whole number up to 1024',
            },
        },
        runtime: {
            name: {
                label: '@:data.presets.name.label',
                placeholder: '@:data.presets.name.placeholder',
            },
        },
    },

    passwords: {
        generate: 'Generate',
        clear: 'Clear',
        unsaved: '(Unsaved Settings)',
        presets: 'Presets',
        current: 'Current Preset',
        available: 'Available Presets',
        rename: 'Rename',
        delete: 'Delete',
        reset: 'Reset to Default',
    },

    messages: {
        delete: 'This preset will be deleted. The operation cannot be undone. Are you sure to continue? ',
        reset: 'All customizations to presets will be restored. The operation cannot be undone. Are you sure to continue? ',
        empty: 'No characters selected to generate passwords',
        included: '{group} are included',
        excluded: '{group} are excluded',
        copied: 'Password copied to clipboard',
    },
}