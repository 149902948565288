<template>
  <ion-app>
    <ion-menu content-id="main" type="overlay">
      <ion-header translucent>
        <ion-menu-toggle>
          <ion-toolbar>
            <ion-buttons slot="start">
              <ion-button>
                <i
                  slot="icon-only"
                  class="fa-fw fa-duotone fa-chevron-left"
                ></i>
              </ion-button>
            </ion-buttons>
            <ion-title>{{ $t("vvip.common.preferences") }}</ion-title>
          </ion-toolbar>
        </ion-menu-toggle>
      </ion-header>

      <ion-content>
        <ion-list>
          <ion-list-header>{{ $t("data.generator.label") }}</ion-list-header>
          <vvip-item
            :data="{
              value: vvip.data.values.generator.auto,
              structure: vvip.data.structure.generator.fields.auto,
              click: passwords.toggle.auto,
            }"
          />
        </ion-list>
        <ion-list>
          <ion-list-header>{{ $t("vvip.data.app.label") }}</ion-list-header>
          <vvip-item
            :data="{
              value: vvip.data.values.app.locale,
              structure: vvip.data.structure.app.fields.locale,
              click: passwords.edit.locale,
            }"
          />
          <vvip-item
            :data="{
              value: vvip.data.values.app.dark,
              structure: vvip.data.structure.app.fields.dark,
              click: passwords.edit.dark,
            }"
          />
        </ion-list>
      </ion-content>
      <ion-footer translucent>
        <ion-toolbar>
          <ion-item detail="false" @click="passwords.about" lines="none">
            {{ $t("vvip.common.about_app", { app: $t("app.name") }) }}
            <div slot="end">
              <i class="fa-fw fa-duotone fa-chevron-right"></i>
            </div>
          </ion-item>
        </ion-toolbar>
      </ion-footer>
    </ion-menu>
    <ion-router-outlet id="main" />
  </ion-app>
</template>

<script>
import {
  IonApp,
  IonRouterOutlet,
  IonHeader,
  IonFooter,
  IonToolbar,
  IonTitle,
  IonContent,
  IonMenu,
  IonMenuToggle,
  IonList,
  IonListHeader,
  IonItem,
  IonButtons,
  IonButton,
} from "@ionic/vue";

import { defineComponent } from "vue";

import { vvip_item } from "@/js/vvip";
import { passwords } from "@/js/passwords";

export default defineComponent({
  name: "App",
  components: {
    IonApp,
    IonRouterOutlet,
    IonHeader,
    IonFooter,
    IonToolbar,
    IonTitle,
    IonContent,
    IonMenu,
    IonMenuToggle,
    IonList,
    IonListHeader,
    IonItem,
    IonButtons,
    IonButton,
    "vvip-item": vvip_item,
  },
  setup() {
    return {};
  },
  mounted() {
    passwords.mount();
  },
});
</script>

<style scoped>
ion-buttons + ion-title {
  padding-inline: 0;
}

ion-menu ion-content {
  --background: var(--ion-item-background, var(--ion-background-color, #fff));
}

ion-list {
  border-bottom: 1px solid
    var(
      --ion-item-border-color,
      var(--ion-border-color, var(--ion-color-step-150, rgba(0, 0, 0, 0.13)))
    );
}

ion-footer ion-item {
  font-size: 0.875rem;
}
</style>